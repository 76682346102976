import React from "react";
import { StyledContainer } from "./loading.style";
import { StyledSpinner } from "../../components/form/loading/loading.style";
import { CgSpinnerTwo } from "react-icons/cg";


export const Loading = () => {
    return (
        <StyledContainer>
            <StyledSpinner>
                <CgSpinnerTwo color="#1976D2" size={60} />
            </StyledSpinner>
        </StyledContainer>
    )
}