import React, { useEffect } from "react";
import { FaApple, FaGoogle } from "react-icons/fa";
import {
  StyledSignUpBox,
  StyledSingUpContainer,
  StyledSingUpFormContainer,
  Button,
  Container,
  StyledHeader,
  StyledTitle,
  Text,
  StyledBg,
} from "./styles";
import { Features } from "./features/features.component";
import { LoginForm } from "./login-form/login-form.component";
import { signInWithGoogle } from "../../services/firebase/auth";
import { Header } from "./header/header.component";

const Auth = ({ setStage }) => {
  const handleSigninWithGoogle = () => {
    signInWithGoogle();
  };
  const handleSigninWithApple = () => {
    setStage(2);
  };

  return (
    <StyledSingUpContainer>
      <StyledBg src="../../../../../../images/bg.png" alt="" />
      <StyledSingUpFormContainer>
        <Header />
        <Container>
          <StyledSignUpBox>
            <StyledHeader>
              <StyledTitle>{"Get started with One Church Hub"}</StyledTitle>
            </StyledHeader>
            <Button onClick={handleSigninWithGoogle}>
              <FaGoogle size={20} color="#D62D20" />
              <Text>Sign in with Google</Text>
            </Button>
            <Button onClick={handleSigninWithApple}>
              <FaApple size={24} color="#000000" />
              <Text>Sign in with Apple</Text>
            </Button>
          </StyledSignUpBox>
          <LoginForm />
        </Container>
      </StyledSingUpFormContainer>
      <Features />
    </StyledSingUpContainer>
  );
};

export default Auth;
