import React from "react";

const CloseIcon = ({ width = "14px", height = "14px" }) => (
  <svg width={width} height={height} viewBox="0 0 14 14">
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g transform="translate(-911.000000, -902.000000)">
        <g id="Group-9-Copy-2" transform="translate(330.000000, 877.000000)">
          <g id="ic-close" transform="translate(576.000000, 20.000000)">
            <polygon
              id="Shape"
              fill="#B9B9B9"
              fillRule="nonzero"
              points="19 6.41 17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12"
            ></polygon>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default CloseIcon;