import React, { useEffect, useState } from "react";
import Auth from "../components/auth";
import Form from "../components/form";
import Payment from "../components/payment";
import Confirmation from "../components/confirmation";
import { useAuth } from "../contexts/auth.context";


const Stages = {
  Auth: 1,
  Form: 2,
  Payment: 3,
  Confirmation: 4,
}

const RegisterPage = () => {
  const { user } = useAuth();
  const [stage, setStage] = useState(user ? Stages.Form : Stages.Auth);

  useEffect(() => {
    if (user) {
      setStage(Stages.Form)
    }
    else {
      setStage(Stages.Auth)
    }
  }, [user])

  if (stage === Stages.Auth) return <Auth setStage={setStage} />;
  if (stage === Stages.Form) return <Form setStage={setStage} />;
  if (stage === Stages.Payment) return <Payment setStage={setStage} />;
  if (stage === Stages.Confirmation) return <Confirmation setStage={setStage} />;

  return <></>
};

export default RegisterPage;
