import React from "react";
import { Container } from "./styles";

const Payment = ({ setStage }) => {
  return (
    <Container>
      <p>This is the payment screensss</p>
      <button onClick={() => setStage(4)}>Continue</button>
    </Container>
  );
};

export default Payment;
