import { doc, getDoc, serverTimestamp, setDoc, updateDoc } from "firebase/firestore";
import { db } from "..";

const USERS_COLLECTION_NAME = 'users';

export const getUser = async (userId) => {
    const userDoc = doc(db, USERS_COLLECTION_NAME, userId);
    const userSnapshot = await getDoc(userDoc)
    if (userSnapshot.exists()) {
        return {
            id: userSnapshot.id,
            ...userSnapshot.data()
        }
    }
    return null;
}

export const createUser = async (authenticatedUser) => {
    const { uid, displayName, email, photoURL } = authenticatedUser;
    const data = {
        displayName,
        email,
        details: {},
        image: photoURL,
        createdAt: serverTimestamp(),
        updateAt: serverTimestamp(),
    };
    const userDoc = doc(db, USERS_COLLECTION_NAME, uid);
    await setDoc(userDoc, data);
    return {
        id: userDoc.id,
        ...data
    }
}

export const updateDetails = async (userId, details) => {
    const userDoc = doc(db, USERS_COLLECTION_NAME, userId);
    await updateDoc(userDoc, {
        details,
    })
}