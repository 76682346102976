import { createGlobalStyle } from "styled-components";

export const GlobalStyle = createGlobalStyle`
 
  html {
    box-sizing: border-box;
    height: 100%;
    margin: 0;
  }
   
  *,
  *:before,
  *:after {
    box-sizing: border-box;
    margin: 0;
    padding: 0; 
  }
  
  body {
    font-family: 'Inter', sans-serif;
    font-weight: 400;
    font-size: 14px;
    color: #656565;
    background-color: ${props => props.theme.body};
  }
  
  code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
      monospace;
  }
  
  button {
    cursor: pointer;
  }
  a {
    text-decoration: none;
  }
  
  
  `;
