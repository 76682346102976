import { httpsCallable } from 'firebase/functions';
import { functions } from ".";



const getPaymentLinkFunction = httpsCallable(functions, 'getPaymentLink');

export const getPaymentLink = async () => {
    const result = await getPaymentLinkFunction();
    return result.data;
}