
import * as yup from "yup";

export const registrationValidationSchema = yup.object().shape({
  category: yup.string().required("category"),
  region: yup.string().required("region"),
  city: yup.string().required("city"),
  address: yup.string().required("address"),
  postCode: yup.string().required("postCode"),
  phoneNumber: yup.string().required("phoneNumber")
});