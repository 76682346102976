import React, { useEffect, useState } from "react";
import {
  Container,
  FormImage,
  FormImageSection,
  StyledCenter,
  StyledContinueButton,
  StyledFormGroup,
  StyledFormHeading,
  StyledFormSection,
  StyledInput,
  StyledInputCenter,
  StyledLabel,
  StyledSection,
  StyledSelect,
  StyledSignOutButton,
  StyledContainer,
} from "./styles";
import { useAuth } from "../../contexts/auth.context";
import { updateDetails } from "../../services/firebase/firestore/user";
import { getPaymentLink } from "../../services/firebase/functions";
import Loading from "./loading/loading.component";
import { Header } from "../auth/header/header.component";
import { registrationValidationSchema } from "./validation";
import PROVINCES from "../../data/provinces";
import { countryAndCities } from "../../data/country-cities";

const Form = ({ setStage }) => {
  const { user, signOut } = useAuth();
  const [details, setDetails] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [formErrors, setFormErrors] = useState([]);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [cities, setCities] = useState([{ value: "", label: "Select City" }]);

  const categories = [
    { value: "", label: "Select User Category" },
    { value: "church", label: "Church" },
    { value: "business", label: "Business" },
    { value: "ngo", label: "NGO" },
    { value: "individuals", label: "Individuals" },
  ];
  const regions = PROVINCES.map((province) => {
    return {
      value: province.name,
      label: province.name,
    };
  });
  regions.unshift({ value: "", label: "Select Province" });

  const cityMapper = (cities) =>
    cities.map((city) => ({
      value: city.name,
      label: city.name,
    }));

  const getCitiesByProvince = (provinceName) => {
    const provinces = countryAndCities.find(
      (country) => country.name === "Canada"
    )?.provinces;
    return provinces?.find((province) => province.name === provinceName)
      ?.cities;
  };

  const handleProvinceChange = ({ target }) => {
    setDetails({ ...details, region: target.value, city: "" });
    if (!target.value)
      return setCities([{ value: "", label: "Select City" }]);
    const provinces = getCitiesByProvince(target.value);

    setCities([{ value: "", label: "Select City" }, ...cityMapper(provinces)]);
  };

  const handleSave = async () => {
    setIsSubmitted(true);

    const isError = await runValidations(details);
    if (isError) {
      return isError;
    }
    setIsLoading(true);
    await updateDetails(user.uid, details);
    const paymentLink = await getPaymentLink();
    document.location.href = paymentLink.url;
  };

  const handleRedirect = async () => {
    setIsSubmitted(true);

    const isError = await runValidations(details);
    if (isError) {
      return isError;
    }
    setStage(4);
  };

  useEffect(() => {
    if (isSubmitted) {
      runValidations(details);
      setIsSubmitted(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [details, isSubmitted]);

  const runValidations = async (obj) => {
    try {
      await registrationValidationSchema.validate(obj, { abortEarly: false });
      if (isSubmitted) {
        setFormErrors([]);
      }
      return false;
    } catch (err) {
      if (isSubmitted) {
        setFormErrors(err.errors);
      }
      return true;
    }
  };
  return (
    <Container>
      <StyledSection>
        <Header />
        <StyledContainer>
          <StyledCenter>
            <StyledFormHeading>
              One Church Hub Registration Form
            </StyledFormHeading>
            <StyledFormSection>
              <StyledFormGroup>
                <StyledLabel>Category</StyledLabel>
                <FormSelect
                  options={categories}
                  field="category"
                  details={details}
                  onChange={(e) =>
                    setDetails({
                      ...details,
                      category: e.currentTarget.value,
                    })
                  }
                  error={formErrors.includes("category")}
                />
              </StyledFormGroup>
              <StyledFormGroup>
                <StyledLabel>Province</StyledLabel>
                <FormSelect
                  options={regions}
                  field="region"
                  details={details}
                  onChange={handleProvinceChange}
                  error={formErrors.includes("region")}
                />
              </StyledFormGroup>
              <StyledFormGroup>
                <StyledLabel>City</StyledLabel>
                <FormSelect
                  options={cities}
                  field="city"
                  details={details}
                  onChange={(e) =>
                    setDetails({
                      ...details,
                      city: e.currentTarget.value,
                    })
                  }
                  error={formErrors.includes("city")}
                />
              </StyledFormGroup>
              <StyledFormGroup>
                <StyledLabel>Address</StyledLabel>
                <FormInput
                  placeholder="Address"
                  field="address"
                  details={details}
                  onChange={setDetails}
                  error={formErrors.includes("address")}
                />
              </StyledFormGroup>
              <StyledInputCenter>
                <StyledFormGroup>
                  <StyledLabel>Post Code</StyledLabel>
                  <FormInput
                    placeholder="Post Code"
                    field="postCode"
                    type="text"
                    details={details}
                    onChange={setDetails}
                    error={formErrors.includes("postCode")}
                  />
                </StyledFormGroup>
                <StyledFormGroup>
                  <StyledLabel>Phone Number</StyledLabel>
                  <FormInput
                    placeholder="Phone Number"
                    field="phoneNumber"
                    type="number"
                    details={details}
                    onChange={setDetails}
                    error={formErrors.includes("phoneNumber")}
                  />
                </StyledFormGroup>
              </StyledInputCenter>

              <StyledFormGroup>
                <StyledLabel>Designation</StyledLabel>
                <FormInput
                  placeholder="Designation (Optional)"
                  field="designation"
                  details={details}
                  onChange={setDetails}
                />
              </StyledFormGroup>
              <StyledContinueButton disabled={isLoading} onClick={handleSave}>
                {isLoading ? "Saving..." : "Continue to pay now"}
              </StyledContinueButton>

              <StyledContinueButton
                $isSecondary
                disabled={isLoading}
                onClick={handleRedirect}
              >
                Continue to pay later
              </StyledContinueButton>

              <StyledSignOutButton OutButton onClick={signOut}>
                Sign Out
              </StyledSignOutButton>
            </StyledFormSection>
            {isLoading && <Loading />}
          </StyledCenter>

          <FormImageSection>
            <FormImage src="../../../../../../images/success.png" alt="" />
          </FormImageSection>
        </StyledContainer>
      </StyledSection>
    </Container>
  );
};

export default Form;

const FormInput = ({
  details,
  field,
  error,
  onChange,
  placeholder,
  type = "text",
}) => (
  <StyledInput
    error={error}
    type={type}
    placeholder={placeholder}
    value={details[field]}
    onChange={(e) => onChange({ ...details, [field]: e.currentTarget.value })}
  />
);

const FormSelect = ({ options, error, field, details, onChange }) => (
  <StyledSelect error={error} value={details[field]} onChange={onChange}>
    {options.map(({ value, label }) => (
      <option key={value} value={value}>
        {label}
      </option>
    ))}
  </StyledSelect>
);
