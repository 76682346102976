import { styled } from "styled-components";

export const Container = styled.div`
  width: 100%;
  background: #f0f0f0;
  position: relative;
`;
export const StyledCenter = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr;
  height: calc(100vh - 61px);
  background: #f0f0f0;
  position: relative;
  @media screen and (min-width: 992px) {
    grid-template-columns: 50% 1fr;
  }
`;
export const StyledConfirmationBg = styled.img`
  position: absolute;
  left: 0;
  top: -40px;
  @media screen and (min-width: 992px) {
    grid-template-columns: 50% 1fr;
    top: 0px;
  }
`;

export const StyledImageSection = styled.div`
  position: relative;
  &:before {
    content: "";
    position: absolute;
    width: 300px;
    height: 400px;
    top: 20%;
    right: 20%;
    background: #e9e8e8;
    border-radius: 40px;
    transform: rotate(65deg);
    z-index: 1;
  }
  &:after {
    content: "";
    position: absolute;
    width: 300px;
    height: 400px;
    top: 50%;
    background: #e9e8e8;
    border-radius: 40px;
    transform: rotate(65deg);
    z-index: 1;
  }
`;
export const StyledImage = styled.img`
  z-index: 2;
  border-radius: 40px;
  width: 100%;
  position: absolute;
  top: 25%;
  padding:20px;
  display: none;
  @media screen and (min-width: 992px) {
    display: block;
  }
`;
export const StyledSection = styled.div`
  width: 76%;
  margin: 0 auto;
  height: auto;
  flex-direction: column;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  @media screen and (min-width: 992px) {
    height: calc(100vh - 61px);
  }
`;
export const StyledTitle = styled.div`
  color: #000;
  font-size: 26px;
  font-weight: 900;
  margin-bottom: 20px;
  @media screen and (min-width: 992px) {
    font-size: 40px;
  }
`;
export const StyledContent = styled.div`
  color: #000;
  font-size: 14px;
  margin-bottom: 20px;
`;
export const StyledFeatures = styled.div`
  z-index: 3;
  padding: 20px;
  border-radius: 10px;
  background: #fff;
  width: 80%;
  bottom: 14%;
  position: inherit;
  left: 40px;
  margin-top: 50px;
  @media screen and (min-width: 992px) {
    position: absolute;
    width: 330px;
  }
`;
export const StyledFeaturesTitle = styled.div`
  font-size: 20px;
  color: #000;
  font-weight: 600;
  margin-bottom: 10px;
`;
export const StyledListSection = styled.div``;
export const StyledList = styled.div``;
export const StyledListItem = styled.div`
  font-size: 14px;
  color: #000;
  margin-bottom: 6px;
  display: flex;
  align-items: center;
  gap: 8px;
`;
export const StyledContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
`;
export const StyledContinueButton = styled.button`
  background: #3478f6;
  padding: 10px 16px;
  border: 0;
  color: #fff;
  font-size: 14px;
  font-weight: 600;
  border-radius: 50px;
  margin-top: 20px;
`;
