import Modal from "../../modal/modal.component";
import {
	StyledLoading,
	StyledSpinner,
} from "./loading.style";
import { CgSpinnerTwo } from "react-icons/cg";


const Loading = ({ setIsLoading }) => {
	return (

		<Modal
			backdropClose={false}
			title=""
			active
			width="50%"
			height="600px"
			closePopUp={() => setIsLoading(false)}
		>
			<StyledLoading>
				<StyledSpinner>
					<CgSpinnerTwo color="#1976D2" size={60} />
				</StyledSpinner>
				Please wait, Redirect to payment page
			</StyledLoading>
		</Modal>
	)

};

export default Loading;
