import * as fbAuth from "firebase/auth";
import { auth } from ".";

export const signInWithGoogle = async () => {
    const provider = new fbAuth.GoogleAuthProvider();
    provider.setCustomParameters({
        prompt: "select_account"
    });
    const result = await fbAuth.signInWithPopup(auth, provider);
    fbAuth.GoogleAuthProvider.credentialFromResult(result);
    return result.user;
}

export const signInWithApple = async () => {
    const provider = new fbAuth.OAuthProvider('apple.com');
    provider.addScope('email');
    provider.addScope('name');
    const result = await fbAuth.signInWithPopup(auth, provider);
    fbAuth.OAuthProvider.credentialFromResult(result);
}


export const createUserWithEmailAndPassword = async (email, password) => {
    try {
        await fbAuth.createUserWithEmailAndPassword(auth, email, password);
        return { success: true }
    } catch (error) {
        return { success: false, error: error.code }
    }
}

export const signInWithEmailPassword = async (email, password) => {
    try {
        await fbAuth.signInWithEmailAndPassword(auth, email, password);
        return { success: true }
    } catch (error) {
        return { success: false, error: error.code }
    }
}

export const sendPasswordResetEmail = async (email) => {
    try {
        await fbAuth.sendPasswordResetEmail(auth, email);
        return { success: true }
    } catch (error) {
        return { success: false, error: error.code }
    }
}


export const signOut = () => auth.signOut();

export const onAuthStateChange = (callback) => fbAuth.onAuthStateChanged(auth, callback);