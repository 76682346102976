import { styled, css } from "styled-components";

export const Container = styled.div`
  flex-direction: column;
  display: flex;
  align-items: center;
  justify-content: center;
  height: calc(100vh - 90px);
`;

export const StyledPageSection = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background: red;
  @media screen and (min-width: 992px) {
    height: calc(100vh - 90px);
  }
`;

export const StyledLoginBox = styled.div`
  width: 90%;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding-bottom: 50px;
  @media screen and (min-width: 992px) {
    width: 450px;
  }
`;
export const StyledHeader = styled.div`
  text-align: center;
  margin-bottom: 40px;
`;
export const StyledTitle = styled.span`
  font-size: 24px;
  font-weight: 600;
  margin-bottom: 30px;
  color: #000;
  @media screen and (min-width: 992px) {
    font-size: 32px;
  }
`;
export const StyledSubTitle = styled.div`
  font-size: 18px;
`;
export const StyledLoginWithTitle = styled.div`
  ${({ theme }) => css`
    font-size: 16px;
    color: ${theme.colors.primaryText};
    margin-top: 20px;
    border-bottom: 1px solid ${theme.colors.defaultBorderColor};
    margin: 40px auto;
    display: inline-block;
  `}
`;
export const Button = styled.button`
  ${({ theme }) => css`
    width: 90%;
    border: none;
    border-radius: 0.375rem;
    padding: 10px 20px;
    display: flex;
    align-items: center;
    cursor: pointer;
    background: transparent;
    border: 1px solid ${theme.colors.defaultBorderColor};
    transition: background-color 0.3s ease;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 20px;
    font-size: 18px;
    font-weight: 500;
    margin: 10px auto;
  `}
`;
export const Text = styled.span`
  margin-left: 10px;
`;
export const StyledLoginContainer = styled.div`
  width: 100%;
`;
export const ButtonItems = styled.div`
  display: block;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
  @media screen and (min-width: 992px) {
    display: flex;
  }
`;
export const StyledSingUpButton = styled.a`
  ${({ theme }) => css`
    color: ${theme.colors.defaultLinkColor};
    display: block;
    margin-left: 10px;
   
    &:hover {
      text-decoration: underline;
    }
  `}
`;
export const StyledSingUpContainer = styled.div`
  width: 100%;
  flex-direction: column;
  display: grid;
  position: relative;
  grid-template-columns: 1fr;
  @media screen and (min-width: 992px) {
    grid-template-columns: 60% 1fr;
  }
`;
export const StyledBg = styled.img`
    position: absolute;
    left: 0;
    top: 0;
    z-index: -1;
`;


export const StyledSingUpFormContainer = styled.div`
  width: 100%;
  flex-direction: column;
  display:flex;
  align-items: center;
  justify-content: center;
`;

export const StyledSignUpBox = styled.div`
  width: 90%;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding-bottom: 50px;
  @media screen and (min-width: 992px) {
    width: 100%;
  }
`;
export const StyledPrivacyPolicy = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;
export const StyledPrivacyPolicyText = styled.div`
  ${({ theme }) => css`
    font-size: 14px;
    color: ${theme.colors.secondaryText};
    margin-right: 10px;
  `}
`;
export const StyledPrivacyPolicyLink = styled.a`
  ${({ theme }) => css`
    font-size: 14px;
    color: ${theme.colors.secondaryText};
    font-weight: 600;
    &:hover {
      text-decoration: underline;
    }
  `}
`;
