export const countryAndCities = [
    {
      name: "Canada",
      provinces: [
        {
          name: "Chatham-Kent",
          cities: [
            {
              name: "Grand Ave W",
            },
            {
              name: "Tecumseh Rd W",
            },
            {
              name: "Dufferin Ave",
            },
            {
              name: "Riverview Dr",
            },
            {
              name: "King St W",
            },
            {
              name: "Harvey St",
            },
            {
              name: "King St E, Ste 2",
            },
            {
              name: "Richmond St",
            },
            {
              name: "Chatham Site",
            },
          ],
        },
        {
          name: "Chippewas of Kettle and Stony Point First Nation",
          cities: [
            {
              name: "Indian Lane",
            },
          ],
        },
        {
          name: "Moosonee",
          cities: [
            {
              name: "Revillion Rd",
            },
          ],
        },
        {
          name: "South Bruce Peninsula",
          cities: [
            {
              name: "Louisa St",
            },
          ],
        },
        {
          name: "Elliot Lake",
          cities: [
            {
              name: "40 Prince Edward Walk",
            },
            {
              name: "9 Saskatchewan Rd",
            },
            {
              name: "Lawrence Ave",
            },
            {
              name: "50 Hillside Dr N",
            },
  
            {
              name: "ELNOS Building",
            },
            {
              name: "Suite 300",
            },
            {
              name: "Suite 210",
            },
            {
              name: "Spine Rd",
            },
            {
              name: "Veteran's Way",
            },
          ],
        },
        {
          name: "Kincardine",
          cities: [
            {
              name: "Gary St",
            },
            {
              name: "385 Queen St",
            },
          ],
        },
        {
          name: "Goderich",
          cities: [
            {
              name: "North St",
            },
            {
              name: "West St",
            },
            {
              name: "38 North St",
            },
          ],
        },
        {
          name: "St Catharines",
          cities: [
            {
              name: "Martindale Rd",
            },
          ],
        },
        {
          name: "Prince Edward Island",
          cities: [
            {
              name: "Kensington",
            },
            {
              name: "Montague",
            },
            {
              name: "Alberton",
            },
            {
              name: "Charlottetown",
            },
            {
              name: "Wellington",
            },
          ],
        },
        {
          name: "Iroquois Falls",
          cities: [
            {
              name: "Ambridge Dr",
            },
            {
              name: "Veterans Dr",
            },
          ],
        },
        {
          name: "Smiths Falls",
          cities: [
            {
              name: "Cornelia St W",
            },
          ],
        },
        {
          name: "Cobourg",
          cities: [
            {
              name: "William St",
            },
            {
              name: "Courthouse Rd",
            },
            {
              name: "King St E",
            },
            {
              name: "Elgin St W",
            },
            {
              name: "Strathy Rd",
            },
  
            {
              name: "Elgin St E",
            },
          ],
        },
        {
          name: "British Columbia\r",
          cities: [
            {
              name: "Port Coquitlam,",
            },
            {
              name: "Richmond",
            },
          ],
        },
        {
          name: "Trent Hills",
          cities: [
            {
              name: "Grand Rd",
            },
            {
              name: "Bridge St E",
            },
          ],
        },
        {
          name: "N0G 2V0",
          cities: [
            {
              name: "Cayley St",
            },
          ],
        },
        {
          name: "Newfoundland and Labrador",
          cities: [
            {
              name: "Stephenville",
            },
            {
              name: "Grand Falls-Windsor",
            },
            {
              name: "Happy Valley-Goose Bay",
            },
            {
              name: "Labrador City",
            },
            {
              name: "Forteau",
            },
            {
              name: "Corner Brook",
            },
            {
              name: "St. John's",
            },
          ],
        },
        {
          name: "Wasaga Beach",
          cities: [
            {
              name: "14 Ramblewood Dr",
            },
            {
              name: "1367 Mosley St",
            },
          ],
        },
        {
          name: "Espanola",
          cities: [
            {
              name: "Centre St",
            },
            {
              name: "Mead Blvd",
            },
            {
              name: "Suite 2",
            },
            {
              name: "Suite 101",
            },
          ],
        },
        {
          name: "Pelee",
          cities: [
            {
              name: "West Shore Rd",
            },
          ],
        },
        {
          name: "Aurora",
          cities: [
            {
              name: "Bloomington Rd W",
            },
            {
              name: "Wellington St E",
            },
            {
              name: "Bloomington Rd",
            },
          ],
        },
        {
          name: "Huron East",
          cities: [
            {
              name: "28 Centennial Dr",
            },
            {
              name: "9 Goderich St W",
            },
          ],
        },
        {
          name: "Markham",
          cities: [
            {
              name: "Kennedy Rd",
            },
            {
              name: "Cochrane Dr",
            },
            {
              name: "Suite 470",
            },
            {
              name: "John St",
            },
            {
              name: "Steeles Ave E",
            },
            {
              name: "Woodbine Ave",
            },
  
            {
              name: "Thornhill Summit Dr",
            },
            {
              name: "Woodbine Ave, Ste 102-103",
            },
            {
              name: "Suite 200",
            },
            {
              name: "Woodbine Ave, Ste 102 & 103",
            },
            {
              name: "Yonge St",
            },
          ],
        },
        {
          name: "Burlington",
          cities: [
            {
              name: "South Service Rd",
            },
          ],
        },
        {
          name: "Chapleau",
          cities: [
            {
              name: "Birch St E",
            },
          ],
        },
        {
          name: "Lambton Shores",
          cities: [
            {
              name: "Kettle Point Plaza",
            },
            {
              name: "Deacon St",
            },
            {
              name: "Arkona Rd",
            },
          ],
        },
        {
          name: "Point Edward",
          cities: [
            {
              name: "Exmouth St",
            },
          ],
        },
        {
          name: "Akdeniz",
          cities: [
            {
              name: "Antalya",
            },
          ],
        },
        {
          name: "Pic Mobert First Nation",
          cities: [{}],
        },
        {
          name: "Kirkland Lake",
          cities: [
            {
              name: "Kirkland St W",
            },
            {
              name: "Station Rd N",
            },
  
            {
              name: "Duncan Ave N",
            },
          ],
        },
        {
          name: "Southgate",
          cities: [
            {
              name: "Artemesia St S",
            },
          ],
        },
        {
          name: "Orangeville",
          cities: [
            {
              name: "Elizabeth St",
            },
          ],
        },
        {
          name: "Toronto",
          cities: [
            {
              name: "Suite 211",
            },
            {
              name: "3025 Kingston Rd",
            },
            {
              name: "1871 O'Connor",
            },
            {
              name: "Suite 325",
            },
            {
              name: "301 Broadview Ave",
            },
            {
              name: "1761 Sheppard Ave E",
            },
            {
              name: "Spadina Rd",
            },
            {
              name: "Broadview Ave",
            },
            {
              name: "80 Sheppard Ave E",
            },
            {
              name: "Suite 300",
            },
            {
              name: "Suite 2005",
            },
            {
              name: "Suite 106A",
            },
            {
              name: "2468 Eglinton Ave W",
            },
            {
              name: "University Ave",
            },
            {
              name: "Silver Star Blvd",
            },
            {
              name: "2 Carlton St, Suite 1005",
            },
            {
              name: "Suite 1010",
            },
            {
              name: "277 Victoria St",
            },
            {
              name: "Suite 106",
            },
            {
              name: "5th Fl",
            },
            {
              name: "111 Gloucester Grv",
            },
            {
              name: "70 Martin Ross Ave",
            },
            {
              name: "Suite 218",
            },
            {
              name: "180 Queen St W",
            },
            {
              name: "1124 Finch Ave W",
            },
            {
              name: "St Mathew's United Church",
            },
            {
              name: "Bamburgh Circle",
            },
            {
              name: "Grenville St",
            },
            {
              name: "Suite 401",
            },
            {
              name: "Oakwood Ave",
            },
            {
              name: "Suite 102",
            },
            {
              name: "2141 Jane St,",
            },
            {
              name: "Gordon Baker Rd",
            },
            {
              name: "Leslie St, Suite 211 and 212",
            },
            {
              name: "Suite 305",
            },
            {
              name: "20 Gerrard St E",
            },
            {
              name: "661 Yonge St",
            },
            {
              name: "540 Finch Ave W",
            },
            {
              name: "2395 Bayview Ave",
            },
            {
              name: "158 Spadina Rd",
            },
            {
              name: "Eglinton Ave E",
            },
            {
              name: "4800 Sheppard Ave E",
            },
            {
              name: "Yonge St",
            },
            {
              name: "Suite 1200",
            },
            {
              name: "Jane St",
            },
            {
              name: "Lawrence Ave W",
            },
            {
              name: "Canadian Tire",
            },
            {
              name: "Suite 703",
            },
            {
              name: "1133 Leslie St, Suite 211 and 212",
            },
            {
              name: "45 Sheppard Ave E",
            },
            {
              name: "2nd Fl",
            },
            {
              name: "1255 The Queensway",
            },
            {
              name: "Finch Ave E",
            },
            {
              name: "1 Leaside Park Dr",
            },
            {
              name: "Toronto Star Building",
            },
            {
              name: "489 Queen Street East",
            },
            {
              name: "Friends' Meeting House",
            },
            {
              name: "Suite 500",
            },
            {
              name: "Queen St W",
            },
            {
              name: "Suite 502",
            },
            {
              name: "Suite 224",
            },
            {
              name: "Bloor St E",
            },
            {
              name: "Unit 9",
            },
            {
              name: "2141 Jane St",
            },
            {
              name: "791 St Clair Ave W",
            },
            {
              name: "439 University Ave",
            },
            {
              name: "uite 701",
            },
            {
              name: "196 Beverley St",
            },
            {
              name: "140 The Esplanade",
            },
            {
              name: "Martin Ross Ave",
            },
            {
              name: "3rd Fl",
            },
            {
              name: "Keele St",
            },
            {
              name: "27 King's College Circle",
            },
            {
              name: "Spadina Ave",
            },
            {
              name: "55 Emmett Ave",
            },
            {
              name: "1364 Dundas St W",
            },
            {
              name: "80 Bond St",
            },
            {
              name: "1911 Kennedy Rd",
            },
            {
              name: "Dufferin St",
            },
            {
              name: "3495 Lawrence Ave E",
            },
            {
              name: "646 St Clair Ave W",
            },
            {
              name: "Lansdowne Ave",
            },
            {
              name: "Wilson Ave",
            },
            {
              name: "Women's College Hospital",
            },
            {
              name: "Toronto Harbour Light Ministrie",
            },
            {
              name: "Suite 202",
            },
            {
              name: "Victoria Park Ave",
            },
  
            {
              name: "655 Spadina Ave",
            },
            {
              name: "Markington Square Plaza",
            },
            {
              name: "789 Yonge St",
            },
            {
              name: "100 Lippincott St",
            },
            {
              name: "720 Spadina Ave",
            },
            {
              name: "635 Markham St",
            },
            {
              name: "Suite 223",
            },
            {
              name: "73 Regent Park Blvd",
            },
            {
              name: "Suite 503",
            },
            {
              name: "Gloucester Grv",
            },
            {
              name: "300 Silver Star Blvd",
            },
            {
              name: "747 Don Mills Rd",
            },
            {
              name: "333 Sherbourne St",
            },
            {
              name: "Suite 206",
            },
            {
              name: "36B Prince Arthur Ave",
            },
            {
              name: "Victoria St",
            },
            {
              name: "33 Victoria St",
            },
            {
              name: "479 Dundas St W",
            },
            {
              name: "1619 Queen St E",
            },
            {
              name: "St Matthew's United Church",
            },
            {
              name: "Markham St",
            },
            {
              name: "208 Oakwood Ave",
            },
            {
              name: "543 Yonge St",
            },
            {
              name: "Suite 1319",
            },
            {
              name: "2756 Victoria Park Ave",
            },
            {
              name: "Suite 201",
            },
            {
              name: "91 Bellevue Ave",
            },
            {
              name: "Glen Watford Dr",
            },
            {
              name: "Suite 105",
            },
            {
              name: "47 Sheppard Ave E",
            },
            {
              name: "2700 Dufferin S",
            },
            {
              name: "Dundas St W",
            },
            {
              name: "Inside the Church of St. Peter and St. Simon-the-Apostle",
            },
            {
              name: "525 Bloor St E",
            },
            {
              name: "Hove St",
            },
            {
              name: "760 College St",
            },
            {
              name: "225 Queen St E",
            },
            {
              name: "931 Yonge St",
            },
            {
              name: "315 Dundas St E",
            },
            {
              name: "3300 Bloor St W",
            },
            {
              name: "3050 Lawrence Ave E",
            },
            {
              name: "365 Bloor St E",
            },
            {
              name: "Woodbine Downs Blvd",
            },
            {
              name: "Eglinton Ave W",
            },
            {
              name: "Korean Cultural Centre",
            },
            {
              name: "Clair Ave W",
            },
            {
              name: "Richmond St W",
            },
            {
              name: "176 Elm St",
            },
            {
              name: "439 University Ave, 5th Fl,",
            },
            {
              name: "Bayview Ave",
            },
            {
              name: "Humber College Blvd",
            },
            {
              name: "789 Yonge S",
            },
            {
              name: "76 Grenville St",
            },
            {
              name: "Suite 200",
            },
            {
              name: "1651 Keele St",
            },
            {
              name: "350 Victoria St",
            },
            {
              name: "476 Grace St",
            },
            {
              name: "558 Bathurst St",
            },
            {
              name: "20 Spadina Rd",
            },
            {
              name: "Sheppard Ave E",
            },
            {
              name: "2398 Yonge St",
            },
            {
              name: "11A Glen Watford",
            },
            {
              name: "St Michael's Health Centre",
            },
            {
              name: "1170 Sheppard Ave W",
            },
            {
              name: "Toronto Harbour Light Ministries",
            },
            {
              name: "Simon-the-Apostle",
            },
            {
              name: "Suite 1000",
            },
            {
              name: "526 Richmond St E",
            },
            {
              name: "Finch Ave W",
            },
            {
              name: "512 Jarvis St",
            },
            {
              name: "Suite 100",
            },
            {
              name: "Consumers Rd",
            },
            {
              name: "Suite 221",
            },
            {
              name: "Lake Shore Blvd E",
            },
            {
              name: "Mansur Terrace",
            },
            {
              name: "793 Lawrence Ave W",
            },
            {
              name: "King St W",
            },
            {
              name: "Lawrence Ave E",
            },
            {
              name: "Suite 211 and 212",
            },
            {
              name: "Drewry Ave",
            },
            {
              name: "Suite 400",
            },
            {
              name: "729 St Clair Ave W",
            },
            {
              name: "800 Bay St",
            },
            {
              name: "59 Adelaide St E",
            },
          ],
        },
        {
          name: "Blind River",
          cities: [
            {
              name: "Suite 210",
            },
            {
              name: "Unit B",
            },
            {
              name: "62 Queen Ave",
            },
            {
              name: "Suite 205",
            },
            {
              name: "147 Woodward Ave",
            },
            {
              name: "15 Hanes Ave",
            },
            {
              name: "Hanes Ave",
            },
          ],
        },
        {
          name: "Gatineau",
          cities: [
            {
              name: "North Towe",
            },
            {
              name: "Wellington St, North Tower",
            },
            {
              name: "Terrasses de la Chaudière",
            },
            {
              name: "Wellington St",
            },
            {
              name: "Wellington St, North Tower,",
            },
            {
              name: "North Tower",
            },
          ],
        },
        {
          name: "Bluewater",
          cities: [
            {
              name: "Albert St",
            },
            {
              name: "King St",
            },
            {
              name: "London Rd, RR 5",
            },
          ],
        },
        {
          name: "Ontario",
          cities: [
            {
              name: "Stouffville",
            },
            {
              name: "Oahawa",
            },
            {
              name: "Newmarket",
            },
            {
              name: "North York",
            },
            {
              name: "Hawkesbury",
            },
            {
              name: "Aylmer",
            },
            {
              name: "Barrie",
            },
            {
              name: "Sarnia",
            },
            {
              name: "320 Bayfield St, Barrie",
            },
            {
              name: "Welland",
            },
            {
              name: "Beamsville",
            },
            {
              name: "Owen Sound",
            },
            {
              name: "Cambridge",
            },
            {
              name: "Belleville",
            },
            {
              name: "St. Catharines",
            },
            {
              name: "Georgetown",
            },
            {
              name: "Chatham",
            },
            {
              name: "Sault Ste Marie",
            },
            {
              name: "Windsor",
            },
            {
              name: "Pickering",
            },
            {
              name: "Suite 100-110 ,Barrie",
            },
            {
              name: "N9C 1B1",
            },
            {
              name: "Wallaceburg",
            },
            {
              name: "St. Thomas",
            },
            {
              name: "Thornhill",
            },
            {
              name: "Vaughan ",
            },
            {
              name: "370 Bayview Dr, 3rd Flr  Barrie",
            },
            {
              name: "Unit 143 Barrie",
            },
            {
              name: "Waterloo",
            },
            {
              name: "Oshawa",
            },
            {
              name: "Scarborough",
            },
            {
              name: "Suite 200 Barrie",
            },
            {
              name: "Malton",
            },
            {
              name: "Wheatley",
            },
            {
              name: "Napanee",
            },
            {
              name: "Kitchener",
            },
            {
              name: "Aurora",
            },
            {
              name: "Suite 63A, Barrie",
            },
            {
              name: "50 Owen St, Barrie",
            },
            {
              name: "Niagara Falls",
            },
            {
              name: "Milton",
            },
            {
              name: "Oakville",
            },
            {
              name: "Hearst",
            },
            {
              name: "Ajax",
            },
            {
              name: "Vaughan",
            },
            {
              name: "Fergus",
            },
            {
              name: "Tilbury",
            },
            {
              name: "London",
            },
            {
              name: "Ridgetown",
            },
            {
              name: "Whitby",
            },
            {
              name: "2nd Flr, Barrie",
            },
            {
              name: "Caledon",
            },
            {
              name: "chmond Hill",
            },
            {
              name: "Richmond Hill,",
            },
            {
              name: "Etobicoke",
            },
            {
              name: "Vanier",
            },
            {
              name: "Ottawa",
            },
            {
              name: "Sutton",
            },
            {
              name: "Suite 301 Barrie",
            },
            {
              name: "Mississauga",
            },
            {
              name: "Unit 5, Essa, Barrie",
            },
            {
              name: " Barrie",
            },
            {
              name: "Stratford",
            },
            {
              name: "49 Owen St, Barrie",
            },
            {
              name: "Suite 106, Barrie",
            },
            {
              name: "Thunder Bay",
            },
            {
              name: "Niagara-on-the-Lake",
            },
            {
              name: "Brampton",
            },
            {
              name: "80 Bradford St, West Wing,  Unit 12, Barrie",
            },
            {
              name: "Suite 201, Barrie",
            },
            {
              name: "48 Owen St, Barrie",
            },
            {
              name: "Bradford",
            },
            {
              name: "Timmins",
            },
            {
              name: "490 Huronia Rd Barrie",
            },
            {
              name: "Alliston",
            },
            {
              name: "80 Bradford St, Unit 340 Barrie",
            },
            {
              name: "16 Bayfield St,  Barrie",
            },
            {
              name: "Cobourg",
            },
            {
              name: "Dresden",
            },
            {
              name: "Keswick",
            },
            {
              name: "Guelph",
            },
            {
              name: "Bldg M 1st Flr, Rm M139 Barrie",
            },
            {
              name: "Leamington",
            },
            {
              name: "Toronto",
            },
            {
              name: "Peterborough",
            },
            {
              name: "80 Bradford St, West Wing, Unit 12, Barrie",
            },
            {
              name: "St.Joachim,",
            },
            {
              name: "Collingwood",
            },
            {
              name: "Burlington",
            },
            {
              name: "Cornwall",
            },
            {
              name: "Sudbury",
            },
            {
              name: "Markham",
            },
            {
              name: "Kingston",
            },
            {
              name: "Brantford",
            },
            {
              name: "Fort Erie",
            },
            {
              name: "Blenheim",
            },
            {
              name: "Hamilton",
            },
            {
              name: "North Bay",
            },
            {
              name: " Unit 1103B, Barrie",
            },
            {
              name: "Woodstock",
            },
            {
              name: "Orillia",
            },
  
            {
              name: "Brockville",
            },
            {
              name: "Richmond Hill",
            },
          ],
        },
        {
          name: "Timmins",
          cities: [
            {
              name: "Wilson Ave",
            },
            {
              name: " Ross Ave E",
            },
            {
              name: "Suite 202",
            },
            {
              name: "Suite 104",
            },
            {
              name: "Suite 203",
            },
            {
              name: "Ross Ave E",
            },
            {
              name: "Algonquin Blvd E",
            },
            {
              name: "Suite 303",
            },
            {
              name: "Wilson Ave,",
            },
            {
              name: "Wilcox St",
            },
            {
              name: "Suite 201",
            },
          ],
        },
        {
          name: "Port Hope",
          cities: [
            {
              name: "Port Hope and District Chamber of Commerce",
            },
          ],
        },
        {
          name: "Selwyn",
          cities: [{}],
        },
        {
          name: "Ottawa",
          cities: [
            {
              name: "Suite 903",
            },
            {
              name: "Slater St",
            },
            {
              name: "Queensview Dr",
            },
            {
              name: "Russell Rd",
            },
            {
              name: "Rosemount Ave",
            },
            {
              name: "Suite 404",
            },
            {
              name: "3800 Carleton Technology and Training Centre",
            },
            {
              name: " Laurier Ave W",
            },
            {
              name: "Montreal Rd",
            },
            {
              name: "439 University Ave, 5th Fl",
            },
            {
              name: "Elgin St",
            },
            {
              name: "O'Meara St",
            },
            {
              name: "Clarence St",
            },
            {
              name: " Bronson Ave",
            },
            {
              name: "MacLaren St",
            },
            {
              name: " Percy St, Rm 2",
            },
            {
              name: "Slater Street",
            },
            {
              name: "Carling Ave",
            },
            {
              name: "Hilda St",
            },
            {
              name: " MacLaren St",
            },
            {
              name: "Edward Ave",
            },
            {
              name: "Nelson St",
            },
            {
              name: " Colonel By Dr",
            },
            {
              name: " Eccles St",
            },
            {
              name: " Gilmour St",
            },
            {
              name: "Joseph Blvd",
            },
            {
              name: "homas More Priv",
            },
            {
              name: "Penny Dr",
            },
            {
              name: "Colonnade Rd",
            },
            {
              name: "Argyle Ave",
            },
            {
              name: "Albert St",
            },
            {
              name: " Montreal Rd",
            },
            {
              name: "Florence St",
            },
            {
              name: " Cooper St",
            },
            {
              name: "Isabella St",
            },
            {
              name: "101 Colonel By Dr",
            },
            {
              name: "Somerset St W",
            },
            {
              name: "Elgin St, 5th Flr",
            },
            {
              name: "Merivale Rd",
            },
            {
              name: "St Laurent Blvd",
            },
            {
              name: "Walkley Rd",
            },
            {
              name: " Boteler St",
            },
            {
              name: "Belfast Rd",
            },
  
            {
              name: "Emerald Plaza",
            },
            {
              name: "Laurier Ave W",
            },
            {
              name: "Carling Executive Park",
            },
            {
              name: "Elgin St, 2nd Flr",
            },
            {
              name: "Murray St",
            },
            {
              name: " Merivale Rd",
            },
            {
              name: "Centrepointe Dr",
            },
            {
              name: "474 Elgin St",
            },
            {
              name: "Wellington St W",
            },
            {
              name: "Ogilvie Rd",
            },
            {
              name: " Bruyère St",
            },
            {
              name: "Bank St, Third Flr",
            },
            {
              name: "Bronson Ave",
            },
            {
              name: "Woodroffe Ave",
            },
            {
              name: "Suite 100",
            },
            {
              name: "Gloucester St",
            },
            {
              name: "Rochester St",
            },
            {
              name: "Stonehurst Ave",
            },
            {
              name: "McArthur Ave",
            },
            {
              name: "Holly Lane",
            },
            {
              name: " 474 Elgin St",
            },
            {
              name: " Ritchie St",
            },
            {
              name: "Kent St",
            },
            {
              name: "130 Colonnade Rd",
            },
            {
              name: "George St",
            },
            {
              name: "Stittsville Main St",
            },
            {
              name: " Argyle Ave",
            },
            {
              name: "Suite 2000",
            },
            {
              name: "Cooper St",
            },
            {
              name: "Laurier Ave E",
            },
            {
              name: "King Edward Ave",
            },
            {
              name: "Carling Ave, Suite 404",
            },
            {
              name: "Colonel By Dr",
            },
            {
              name: "Bank St",
            },
            {
              name: " City Centre Ave",
            },
            {
              name: "94 Centrepointe Dr",
            },
            {
              name: "Princeton Ave",
            },
            {
              name: " Laurier Ave E",
            },
            {
              name: "Myrand Ave",
            },
            {
              name: " 211 Bronson Ave N, Suite 108",
            },
            {
              name: "Aviation Pkwy",
            },
            {
              name: "Boteler St",
            },
            {
              name: "Don Reid Dr",
            },
            {
              name: "Suite 201",
            },
            {
              name: "Suite 200",
            },
            {
              name: "Richmond Rd",
            },
            {
              name: "West Hunt Club Rd",
            },
            {
              name: "Preston St",
            },
            {
              name: "Dupuis St",
            },
          ],
        },
        {
          name: "South Huron",
          cities: [
            {
              name: "349 Main St S",
            },
            {
              name: "Main St S",
            },
          ],
        },
        {
          name: "Harris",
          cities: [
            {
              name: "Armstrong St N",
            },
          ],
        },
        {
          name: "Thunder Bay",
          cities: [
            {
              name: "Dawson Rd",
            },
            {
              name: "Suite 301",
            },
            {
              name: "Suite 103",
            },
            {
              name: "Alloy Dr",
            },
            {
              name: "Golf Links Rd",
            },
            {
              name: "125 Vickers St S",
            },
            {
              name: "Suite 110",
            },
            {
              name: "Court St N",
            },
            {
              name: "Archibald St N",
            },
            {
              name: "Suite 104",
            },
            {
              name: "James St S",
            },
            {
              name: "Brodie St N",
            },
            {
              name: "Cumberland St N",
            },
            {
              name: " Oliver Rd",
            },
            {
              name: "Vickers St S",
            },
            {
              name: "Victoria Ave E",
            },
            {
              name: "Suite 38",
            },
            {
              name: " Cumberland St N",
            },
            {
              name: "Nakina Dr",
            },
            {
              name: "N Syndicate Ave",
            },
            {
              name: " Centennial Square",
            },
            {
              name: "Suite 300",
            },
            {
              name: "Suite 335",
            },
            {
              name: " Alloy Dr",
            },
            {
              name: "Balmoral St",
            },
            {
              name: "Syndicate Ave S",
            },
            {
              name: " Pearl St",
            },
            {
              name: "Mooney Ave",
            },
            {
              name: "Van Norman St",
            },
            {
              name: " Van Norman St",
            },
            {
              name: "Suite 201",
            },
            {
              name: "Red River Rd, Lower Level",
            },
            {
              name: " Ray Blvd",
            },
            {
              name: "Simpson St",
            },
            {
              name: "May St S",
            },
            {
              name: " Lillie St S",
            },
            {
              name: "Castlegreen Dr",
            },
            {
              name: "B Bay St",
            },
            {
              name: "Waterford St",
            },
            {
              name: "Walkover St",
            },
            {
              name: "Wilson St",
            },
            {
              name: " Suite 110",
            },
  
            {
              name: "Harold Cres",
            },
            {
              name: "Oliver Rd",
            },
          ],
        },
        {
          name: "Brockton",
          cities: [
            {
              name: "Suite 200",
            },
            {
              name: "203 Cayley St",
            },
          ],
        },
        {
          name: "Meaford",
          cities: [
            {
              name: " Sykes St N",
            },
            {
              name: "Victoria Street",
            },
            {
              name: "150 North Sykes St",
            },
            {
              name: "Suite 1",
            },
          ],
        },
        {
          name: "North Bay",
          cities: [
            {
              name: "Main St W",
            },
          ],
        },
        {
          name: " Port Hope",
          cities: [
            {
              name: "Toronto Rd",
            },
          ],
        },
        {
          name: "Columbia",
          cities: [
            {
              name: "Surrey, British",
            },
          ],
        },
        {
          name: "Serpent River First Nation",
          cities: [{}],
        },
        {
          name: "Lanark Highlands",
          cities: [
            {
              name: "207 Robertson Dr",
            },
          ],
        },
        {
          name: "Quinte West",
          cities: [
            {
              name: "Dundas St W",
            },
          ],
        },
        {
          name: "Fort William First Nation",
          cities: [
            {
              name: " Anemki Dr",
            },
            {
              name: "Anemki Dr,",
            },
          ],
        },
        {
          name: "Saugeen Shore",
          cities: [
            {
              name: "High St",
            },
            {
              name: "Hwy 21",
            },
            {
              name: "Goderich St",
            },
          ],
        },
        {
          name: "Northwest Territories",
          cities: [
            {
              name: "Yellowknife",
            },
          ],
        },
        {
          name: "King",
          cities: [
            {
              name: "Dew Court",
            },
          ],
        },
        {
          name: "Perth",
          cities: [
            {
              name: "Dufferin St",
            },
          ],
        },
        {
          name: "London",
          cities: [
            {
              name: "Exeter Rd",
            },
            {
              name: "King St",
            },
            {
              name: "Suite 279",
            },
            {
              name: " Wellington St",
            },
            {
              name: "Wellington Rd S",
            },
            {
              name: "285 King St",
            },
            {
              name: "417 Exeter Rd",
            },
            {
              name: "521 Hamilton Rd",
            },
  
            {
              name: "Hamilton Rd",
            },
            {
              name: "Princess Ave",
            },
            {
              name: "520 Hamilton Rd",
            },
            {
              name: "Oxford St W",
            },
            {
              name: "Dundas St",
            },
            {
              name: " Princess Ave",
            },
          ],
        },
        {
          name: "Orillia",
          cities: [
            {
              name: "Memorial Dr",
            },
            {
              name: "Colborne St E",
            },
          ],
        },
        {
          name: "Chippewas of Saugeen First Nation",
          cities: [
            {
              name: "47 Mason Dr",
            },
          ],
        },
        {
          name: " Thunder Bay",
          cities: [
            {
              name: "Suite 38,",
            },
          ],
        },
        {
          name: "Southwest Middlesex",
          cities: [
            {
              name: "Concession Dr",
            },
          ],
        },
        {
          name: "Oakville",
          cities: [
            {
              name: "Trafalgar Rd",
            },
            {
              name: "540 Lakeshore Rd",
            },
          ],
        },
        {
          name: "Sault Ste Marie",
          cities: [
            {
              name: "376 Queen St E",
            },
            {
              name: "426 Queen St E",
            },
            {
              name: "Queen St E",
            },
            {
              name: "440 Northland Rd",
            },
            {
              name: "451 Queen St E",
            },
            {
              name: "Suite 203",
            },
            {
              name: "180 Brock St",
            },
            {
              name: "503 Queen St E",
            },
            {
              name: "503 Queen St E,",
            },
            {
              name: "Suite 200",
            },
            {
              name: "130 Queen St E,",
            },
            {
              name: "344 Queen St E",
            },
            {
              name: "441 Northland Rd",
            },
            {
              name: "170 East St",
            },
            {
              name: "420 Queen St E",
            },
  
            {
              name: "500 Bay St",
            },
            {
              name: "540 Albert St E",
            },
            {
              name: "23 Oakland Ave",
            },
            {
              name: "386 Queen St E",
            },
            {
              name: "750 Great Northern Rd",
            },
            {
              name: "240 McNabb St",
            },
            {
              name: "Suite 480",
            },
            {
              name: "Suite 220",
            },
            {
              name: "Suite 301",
            },
          ],
        },
        {
          name: "Cochrane",
          cities: [
            {
              name: "Centre de Santé Minto Health Centre",
            },
            {
              name: "Sixth Ave",
            },
  
            {
              name: "Third Ave",
            },
          ],
        },
        {
          name: "West Grey",
          cities: [
            {
              name: "154 Garafraxa St N",
            },
          ],
        },
        {
          name: "Grey Highlands",
          cities: [
            {
              name: "24 Toronto St",
            },
            {
              name: "55 Victoria Ave",
            },
            {
              name: "101 Highland Dr",
            },
          ],
        },
        {
          name: "British Columbia",
          cities: [
            {
              name: "Smithers",
            },
            {
              name: "Port Coquitlam",
            },
            {
              name: "Whistler",
            },
            {
              name: "Port Alberni",
            },
            {
              name: "North Vancouver,",
            },
            {
              name: "Duncan",
            },
            {
              name: "Fort St. John",
            },
            {
              name: "Pemberton",
            },
            {
              name: "Nakusp",
            },
            {
              name: "Kimberley",
            },
            {
              name: "Prince Rupert",
            },
            {
              name: "Delta",
            },
            {
              name: "Invermere",
            },
            {
              name: "Sechelt",
            },
            {
              name: "WILLIAMS LAKE",
            },
            {
              name: "Cranbrook",
            },
            {
              name: "Kamloops",
            },
            {
              name: "Fort Nelson",
            },
            {
              name: "Courtenay",
            },
            {
              name: "Surrey",
            },
            {
              name: "West Vancouver",
            },
            {
              name: "Castlegar",
            },
            {
              name: "Campbell River",
            },
            {
              name: "Richmond",
            },
            {
              name: "Kitimat",
            },
            {
              name: "Vancouver",
            },
            {
              name: "Ladner",
            },
            {
              name: "Mission",
            },
            {
              name: "Chilliwack",
            },
            {
              name: "Squamish",
            },
            {
              name: "Fernie",
            },
            {
              name: "Powell River",
            },
            {
              name: "Penticton",
            },
            {
              name: "Vernon",
            },
            {
              name: "Victoria",
            },
            {
              name: "Salmon Arm",
            },
            {
              name: "Coquitlam",
            },
            {
              name: "100 Mile House",
            },
            {
              name: "Sidney",
            },
            {
              name: "Creston",
            },
            {
              name: "Terrace",
            },
            {
              name: "Prince George",
            },
            {
              name: "Nanaimo",
            },
            {
              name: "Kelowna",
            },
            {
              name: "New Westminster",
            },
            {
              name: "Revelstoke",
            },
            {
              name: "Golden",
            },
            {
              name: "Trail",
            },
            {
              name: "Burnaby",
            },
            {
              name: "Langley",
            },
            {
              name: "Maple Ridge",
            },
            {
              name: "Nelson",
            },
            {
              name: "Grand Forks (Boundary region)",
            },
          ],
        },
        {
          name: " Newfoundland and Labrador\r",
          cities: [
            {
              name: "St. John's,",
            },
          ],
        },
        {
          name: "Petrolia",
          cities: [
            {
              name: "Petrolia Line",
            },
            {
              name: "Glenview Rd",
            },
            {
              name: "Oil Heritage Rd",
            },
          ],
        },
        {
          name: " Greater Sudbury",
          cities: [
            {
              name: " Van Horne St",
            },
          ],
        },
        {
          name: "Vaughan",
          cities: [
            {
              name: "Suite 206",
            },
            {
              name: " Jane St",
            },
            {
              name: "Jane St",
            },
            {
              name: "Hesperus Rd",
            },
            {
              name: "Martin Grove Rd",
            },
  
            {
              name: "Bathurst St, Suite 242",
            },
            {
              name: "Clark Ave",
            },
            {
              name: "Major Mackenzie Dr",
            },
            {
              name: "Steeles Ave W",
            },
            {
              name: "Suite 102",
            },
            {
              name: "Keele St",
            },
            {
              name: "Glenkindie Ave",
            },
          ],
        },
        {
          name: " Fort William First Nation",
          cities: [
            {
              name: "Anemki Dr",
            },
          ],
        },
        {
          name: " Prince Edward Island",
          cities: [
            {
              name: "Charlottetown",
            },
          ],
        },
        {
          name: "Marmara",
          cities: [
            {
              name: "Istanbul",
            },
          ],
        },
        {
          name: "Kawartha Lakes",
          cities: [
            {
              name: "Francis St E",
            },
            {
              name: "Lindsay St N",
            },
            {
              name: "Kent St W",
            },
  
            {
              name: "Peel St",
            },
            {
              name: "Wellington Rd",
            },
          ],
        },
        {
          name: " Chippewas of Kettle and Stony Point First Nation",
          cities: [
            {
              name: "Indian Lane",
            },
          ],
        },
        {
          name: "Essex",
          cities: [
            {
              name: "King St E",
            },
            {
              name: " Talbot St S",
            },
          ],
        },
        {
          name: "Moose Cree First Nation",
          cities: [
            {
              name: " Jonathan Cheechoo Dr",
            },
            {
              name: "Jonathan Cheechoo Dr",
            },
          ],
        },
        {
          name: "Brockville",
          cities: [
            {
              name: "King St E",
            },
            {
              name: "Laurier Blvd",
            },
            {
              name: "Charles St",
            },
          ],
        },
        {
          name: "Manitoba",
          cities: [
            {
              name: "Steinbach",
            },
            {
              name: "Winnipeg ",
            },
            {
              name: "Morden",
            },
            {
              name: "Dauphin",
            },
            {
              name: "Arborg",
            },
            {
              name: "Brandon",
            },
            {
              name: "Swan River",
            },
            {
              name: "Winnipeg",
            },
            {
              name: "Neepawa",
            },
            {
              name: "Winkler",
            },
            {
              name: "Thompson",
            },
            {
              name: "Altona",
            },
            {
              name: "Portage la Prairie",
            },
          ],
        },
        {
          name: "Chatham",
          cities: [
            {
              name: "King St W",
            },
          ],
        },
        {
          name: "Hamilton",
          cities: [
            {
              name: "Queen St N",
            },
            {
              name: "Ottawa St N",
            },
          ],
        },
        {
          name: "Carleton Place",
          cities: [
            {
              name: "43B Lansdowne Ave",
            },
            {
              name: "145 Hooper St",
            },
          ],
        },
        {
          name: "Nipigon",
          cities: [{}],
        },
        {
          name: "Collingwood",
          cities: [
            {
              name: " Marie St",
            },
            {
              name: "199 Campbell St",
            },
            {
              name: "Unit 6",
            },
            {
              name: "Collingwood",
            },
            {
              name: "Raglan St",
            },
            {
              name: "Campbell St",
            },
            {
              name: "50 Hume St",
            },
            {
              name: "485 Second St",
            },
            {
              name: " Campbell St",
            },
            {
              name: "Marie St",
            },
          ],
        },
        {
          name: " Aundeck Omni Kaning First Nation",
          cities: [
            {
              name: " Hillside Rd",
            },
          ],
        },
        {
          name: "Nova Scotia",
          cities: [
            {
              name: "Sydney",
            },
            {
              name: "Antigonish",
            },
            {
              name: "Kentville",
            },
            {
              name: "New Glasgow",
            },
            {
              name: "Halifax",
            },
            {
              name: "Little Brook",
            },
            {
              name: "Dartmouth",
            },
          ],
        },
        {
          name: "Leamington",
          cities: [
            {
              name: "Talbot St E",
            },
            {
              name: "Princess St E",
            },
            {
              name: "Princess St",
            },
            {
              name: "Seacliff Dr E",
            },
            {
              name: " Talbot St E",
            },
          ],
        },
        {
          name: "Kingston",
          cities: [
            {
              name: "49 Place D'Armes",
            },
            {
              name: "D'Armes",
            },
  
            {
              name: "Place D'Armes",
            },
            {
              name: "Bagot St",
            },
          ],
        },
        {
          name: "Wroxeter",
          cities: [
            {
              name: " Belmore Line",
            },
          ],
        },
        {
          name: "Kapuskasing",
          cities: [
            {
              name: "Queen St",
            },
            {
              name: "Kimberly Dr",
            },
  
            {
              name: "Government Rd W",
            },
            {
              name: "Kolb Ave",
            },
            {
              name: "Suite 5",
            },
            {
              name: "Ash St",
            },
            {
              name: " Aurora Ave",
            },
            {
              name: " Suite 5",
            },
          ],
        },
        {
          name: "Yukon",
          cities: [
            {
              name: "Whitehorse",
            },
          ],
        },
        {
          name: "Caledon",
          cities: [
            {
              name: "Bolton",
            },
            {
              name: "King St E",
            },
          ],
        },
        {
          name: "Greater Sudbury",
          cities: [
            {
              name: "Elm St",
            },
            {
              name: "Van Horne St",
            },
  
            {
              name: "Notre Dame Ave",
            },
            {
              name: " Applegrove St",
            },
            {
              name: " Oak St",
            },
            {
              name: "Pine St",
            },
            {
              name: "935 Ramsey Lake Rd",
            },
            {
              name: "Suite 101",
            },
            {
              name: "Suite 300",
            },
            {
              name: "Lasalle Blvd",
            },
            {
              name: "Cedar St",
            },
            {
              name: "Suite 103",
            },
            {
              name: "Suite 132",
            },
            {
              name: "Suite 405",
            },
            {
              name: "Xavier St",
            },
            {
              name: "Coulson St",
            },
            {
              name: " Cedar St",
            },
            {
              name: "Suite 102",
            },
            {
              name: "Ramsey Lake Rd",
            },
            {
              name: "St Raphael St",
            },
            {
              name: " Suite 102",
            },
            {
              name: "Paris St",
            },
            {
              name: "Larch St",
            },
            {
              name: "Suite 100",
            },
            {
              name: "Suite 603",
            },
            {
              name: "Suite 401",
            },
            {
              name: "Fourth Ave",
            },
            {
              name: "Bruce Ave",
            },
          ],
        },
        {
          name: "Mississauga",
          cities: [
            {
              name: "Watline Ave",
            },
            {
              name: "Eglinton Ave W",
            },
            {
              name: "Mississauga Rd N",
            },
            {
              name: "Central Pkwy W",
            },
            {
              name: " Lakeshore Road E",
            },
            {
              name: "Meyerside Dr",
            },
            {
              name: "Millcreek Dr",
            },
            {
              name: "Westwood Square Mall",
            },
            {
              name: "Derry Road E",
            },
            {
              name: "Malton Community Centre",
            },
  
            {
              name: "Dundas St W",
            },
            {
              name: " Hurontario St W",
            },
            {
              name: "HJA Brown Education Centre",
            },
            {
              name: "Palstan Rd",
            },
            {
              name: "Acorn Place",
            },
            {
              name: "Hurontario St",
            },
            {
              name: "Dundas St E",
            },
            {
              name: "Mississauga Valley Blvd",
            },
            {
              name: "Britannia Road E",
            },
            {
              name: "Battleford Centre",
            },
            {
              name: "Aventura Court",
            },
            {
              name: " Hurontario St",
            },
            {
              name: "Whittle Road",
            },
            {
              name: " Airport Rd",
            },
            {
              name: "Village Centre Place",
            },
            {
              name: "Confederation Pkwy N",
            },
            {
              name: "945 Meyerside D",
            },
            {
              name: "Dundas Towers",
            },
            {
              name: "Suite 116",
            },
            {
              name: "Erin Mills Pkwy",
            },
            {
              name: " Robert Speck Pkwy",
            },
            {
              name: " Whittle Rd",
            },
            {
              name: "7120 Hurontario St",
            },
            {
              name: "Robert Speck Pkwy",
            },
            {
              name: "Dixie Rd",
            },
            {
              name: "Turner Valley Rd",
            },
            {
              name: "Kingsbridge Garden Circle W",
            },
            {
              name: "Meyerside Drive",
            },
            {
              name: "Burnhamthorpe Library",
            },
            {
              name: "Burnhamthorpe Rd W",
            },
          ],
        },
        {
          name: "Hanover",
          cities: [{}],
        },
        {
          name: "Richmond Hill",
          cities: [
            {
              name: "Yonge St",
            },
  
            {
              name: " Yonge St",
            },
            {
              name: "9893 Leslie St",
            },
            {
              name: "10121 Yonge St",
            },
            {
              name: "Times Square",
            },
            {
              name: "Roney Ave",
            },
            {
              name: " Dunlop St",
            },
            {
              name: "Pugsley Ave",
            },
            {
              name: "Leslie St",
            },
            {
              name: "Dunlop St",
            },
          ],
        },
        {
          name: "Nunavut",
          cities: [
            {
              name: "Iqaluit",
            },
          ],
        },
        {
          name: "Haliburton",
          cities: [
            {
              name: "Victoria St",
            },
            {
              name: "Gelert Rd",
            },
          ],
        },
        {
          name: "Minden Hills",
          cities: [{}],
        },
        {
          name: "French River",
          cities: [
            {
              name: "David St",
            },
          ],
        },
        {
          name: "Georgina",
          cities: [
            {
              name: "The Queensway S",
            },
            {
              name: "Church St",
            },
          ],
        },
        {
          name: "Quebec",
          cities: [
            {
              name: "Sherbrooke",
            },
          ],
        },
        {
          cities: [
            {
              name: " Cornwall",
            },
            {
              name: "Ottawa",
            },
            {
              name: "Brampton",
            },
            {
              name: "Smiths Falls",
            },
            {
              name: "North Glengarry",
            },
            {
              name: "Addington Highlands",
            },
            {
              name: " Welland",
            },
            {
              name: "Central Frontenac",
            },
            {
              name: "Burk's Falls",
            },
            {
              name: "Peterborough",
            },
            {
              name: "Madoc",
            },
            {
              name: "The Nation Municipality",
            },
            {
              name: "Innisfil ",
            },
            {
              name: "Gananoque",
            },
            {
              name: "London",
            },
            {
              name: " Mississauga",
            },
            {
              name: ", St Catharines",
            },
            {
              name: "Port Perry",
            },
            {
              name: "Sundridge",
            },
            {
              name: " Kingston",
            },
            {
              name: "Clearview",
            },
            {
              name: "Casselman",
            },
            {
              name: " Waterloo",
            },
            {
              name: " St Marys",
            },
            {
              name: " St Catharines",
            },
            {
              name: "Hamilton (Township)",
            },
            {
              name: " Gananoque",
            },
            {
              name: " Barrie",
            },
            {
              name: "2nd Floor, Midland",
            },
            {
              name: "North Grenville",
            },
            {
              name: "Greater Napanee",
            },
            {
              name: "Ste 1, Bracebridge",
            },
            {
              name: "Parry Sound",
            },
            {
              name: "St Catharines",
            },
            {
              name: " Shelburne",
            },
            {
              name: "Port Colborne",
            },
            {
              name: "Temiskaming Shores",
            },
            {
              name: "South River",
            },
            {
              name: "Seguin",
            },
            {
              name: "Unit A, Midland",
            },
            {
              name: "Alfred and Plantagenet",
            },
            {
              name: "Collingwood",
            },
            {
              name: " Minto",
            },
            {
              name: "320 Bayfield St, Suite 63A, Barrie\nON L4M 3C1\n\nTelephone\nOffice: 705-241-2501\nWebsite",
            },
            {
              name: "Pickering",
            },
            {
              name: " Collingwood",
            },
            {
              name: " Casselman",
            },
            {
              name: " Belleville",
            },
            {
              name: "Lincoln",
            },
            {
              name: " Parry Sound",
            },
            {
              name: "Windsor",
            },
            {
              name: "St Thomas",
            },
            {
              name: "Tillsonburg",
            },
            {
              name: ", Toronto",
            },
            {
              name: "Malahide",
            },
            {
              name: "500 University Ave Orillia",
            },
            {
              name: " Clarence-Rockland",
            },
            {
              name: "South Frontenac",
            },
            {
              name: "Bonnechere Valley",
            },
            {
              name: " Thorold",
            },
            {
              name: "Milton",
            },
            {
              name: " Thames Centre",
            },
            {
              name: "South Dundas",
            },
            {
              name: "Haldimand",
            },
            {
              name: " Perth",
            },
            {
              name: "Oakville",
            },
            {
              name: " Orillia",
            },
            {
              name: "Picton",
            },
            {
              name: "Orillia",
            },
            {
              name: "West Lincoln",
            },
            {
              name: "Gatineau\nQC",
            },
            {
              name: "Alfred And Plantagenet",
            },
            {
              name: " Stratford",
            },
            {
              name: "Halton Hills",
            },
            {
              name: " Norfolk",
            },
            {
              name: "Richmond Hill",
            },
            {
              name: " Toronto",
            },
            {
              name: " Emo",
            },
            {
              name: " Rainy River",
            },
            {
              name: "Champlain",
            },
  
            {
              name: "Dutton/Dunwich",
            },
            {
              name: " Grimsby",
            },
            {
              name: "Kingston",
            },
            {
              name: "Midland",
            },
            {
              name: "Quinte West",
            },
            {
              name: "Oro-Medonte ",
            },
            {
              name: "Mississauga",
            },
            {
              name: "Six Nations Of The Grand River",
            },
            {
              name: "Wilmot",
            },
            {
              name: " Richmond Hill",
            },
            {
              name: "Woodstock",
            },
            {
              name: "Gatineau",
            },
            {
              name: " North Grenville",
            },
            {
              name: " Milton",
            },
            {
              name: " Kenora",
            },
            {
              name: "Cochrane",
            },
            {
              name: " Huntsville",
            },
            {
              name: " Hamilton",
            },
            {
              name: "Cambridge",
            },
            {
              name: " Southwold",
            },
            {
              name: " Fort Frances",
            },
            {
              name: " Russell",
            },
            {
              name: "Suite 314, Brampton",
            },
            {
              name: "Prince Edward",
            },
            {
              name: "30 Park St",
            },
            {
              name: " Fort Erie",
            },
            {
              name: "Waterloo",
            },
            {
              name: ", Kitchener",
            },
            {
              name: "Hawkesbury",
            },
            {
              name: " West Lincoln",
            },
            {
              name: "Clarence-Rockland",
            },
            {
              name: " Whitby",
            },
            {
              name: "Brockville",
            },
            {
              name: "Trenton",
            },
            {
              name: "Shelburne",
            },
            {
              name: "Brant",
            },
            {
              name: " Gatineau",
            },
            {
              name: "Uxbridge",
            },
            {
              name: " St Thomas",
            },
            {
              name: "Russell",
            },
            {
              name: "Elmsley St N",
            },
            {
              name: "Whitby",
            },
            {
              name: "Vancouver",
            },
            {
              name: " Midland",
            },
            {
              name: " London",
            },
            {
              name: "Springwater",
            },
            {
              name: "Strathroy-Caradoc",
            },
            {
              name: "Centre Part",
            },
            {
              name: " Oshawa",
            },
            {
              name: "Sioux Lookout",
            },
            {
              name: "New Tecumseth",
            },
            {
              name: "West Elgin",
            },
            {
              name: "199 Campbell St, Collingwood",
            },
            {
              name: " Red Lake",
            },
            {
              name: "Cornwall",
            },
            {
              name: "North Stormont",
            },
            {
              name: "Bracebridge",
            },
            {
              name: "Fort Erie",
            },
            {
              name: "Aurora",
            },
            {
              name: " Brampton",
            },
            {
              name: "Suite 300/410, Mississauga",
            },
            {
              name: " Ajax",
            },
            {
              name: "Penetanguishene",
            },
            {
              name: "Belleville",
            },
            {
              name: "Burlington",
            },
            {
              name: "Oshawa",
            },
            {
              name: "Dryden",
            },
            {
              name: "Bancroft",
            },
            {
              name: "Lucan Biddulph",
            },
            {
              name: "Guelph",
            },
            {
              name: " Oakville",
            },
            {
              name: "Carleton Place",
            },
            {
              name: "Orangeville",
            },
            {
              name: "Fort Frances",
            },
            {
              name: "North Bay",
            },
            {
              name: " Woolwich",
            },
            {
              name: "Bradford West Gwillimbury",
            },
            {
              name: " West Nipissing",
            },
            {
              name: "Brantford",
            },
            {
              name: "Rideau Lakes",
            },
            {
              name: "Wellington North",
            },
            {
              name: "South Stormont",
            },
            {
              name: "Machin",
            },
            {
              name: "Hamilton",
            },
            {
              name: "Lyndoch St",
            },
            {
              name: "Prescott",
            },
            {
              name: "Nipissing First Nation",
            },
            {
              name: " Dryden",
            },
            {
              name: "Ajax",
            },
            {
              name: "Rainy River",
            },
            {
              name: "Sioux Narrows-Nestor Falls",
            },
            {
              name: "Ingersoll",
            },
            {
              name: "Suite L-2, Orangeville",
            },
            {
              name: "Perth",
            },
            {
              name: " Burlington",
            },
            {
              name: " Guelph",
            },
            {
              name: "Chrysler Dr",
            },
            {
              name: " Brockville",
            },
            {
              name: "Elgin",
            },
            {
              name: " Ottawa",
            },
            {
              name: "Barrie",
            },
            {
              name: "South Glengarry",
            },
            {
              name: "Kenora",
            },
            {
              name: "Toronto, ",
            },
            {
              name: "Lanark Highlands",
            },
            {
              name: " North Bay",
            },
            {
              name: "Thunder Bay",
            },
            {
              name: "Kitchener",
            },
            {
              name: "Niagara Falls",
            },
            {
              name: "Winchester",
            },
            {
              name: "Westport",
            },
            {
              name: "Centre Wellington",
            },
            {
              name: "Welland",
            },
            {
              name: "Niagara-on-the-Lake",
            },
            {
              name: "Greater Sudbury",
            },
            {
              name: "Niagara-On-The-Lake",
            },
            {
              name: "Ear Falls",
            },
            {
              name: "Park St",
            },
            {
              name: "Thorold",
            },
            {
              name: " Pickering",
            },
            {
              name: " Quinte West",
            },
            {
              name: " Niagara-On-The-Lake",
            },
            {
              name: " North Middlesex",
            },
            {
              name: "Toronto",
            },
            {
              name: " Kitchener",
            },
            {
              name: " Niagara Falls",
            },
            {
              name: "Unit 202, Wasaga Beach",
            },
          ],
        },
        {
          name: "Campbellford",
          cities: [
            {
              name: "Bridge St E",
            },
          ],
        },
        {
          name: "Saskatchewan",
          cities: [
            {
              name: "North Battleford,",
            },
            {
              name: "Weyburn",
            },
            {
              name: "Humboldt",
            },
            {
              name: "Swift Current",
            },
            {
              name: "Moose Jaw",
            },
            {
              name: "Estevan",
            },
            {
              name: "North Battleford",
            },
            {
              name: "Yorkton",
            },
            {
              name: "Saskatoon",
            },
            {
              name: "Prince Albert",
            },
            {
              name: "Regina",
            },
            {
              name: "Nipawin",
            },
          ],
        },
        {
          name: "Oshawa",
          cities: [
            {
              name: "Grassmere Ave",
            },
          ],
        },
        {
          name: " Richmond Hill",
          cities: [
            {
              name: "Suite D",
            },
            {
              name: "Times Square",
            },
  
            {
              name: "Bayview Ave",
            },
          ],
        },
        {
          name: " Kawartha Lakes",
          cities: [
            {
              name: "Kent St W",
            },
            {
              name: "Lindsay St S",
            },
          ],
        },
        {
          name: "Aundeck Omni Kaning First Nation",
          cities: [
            {
              name: "Hillside Rd",
            },
          ],
        },
        {
          name: "Peterborough",
          cities: [
            {
              name: "Milroy Dr",
            },
            {
              name: "Aylmer St S",
            },
            {
              name: "Nordia Building",
            },
            {
              name: "Chamberlain Place",
            },
            {
              name: "King St",
            },
            {
              name: "Chemong Rd",
            },
            {
              name: "Charlotte St",
            },
            {
              name: "Monaghan Rd",
            },
            {
              name: "George St N",
            },
            {
              name: "Jackson Square",
            },
            {
              name: "Sheridan St",
            },
            {
              name: " Monaghan Rd",
            },
            {
              name: "McDonnel St",
            },
            {
              name: "Parkway Mall ",
            },
            {
              name: "Simcoe St",
            },
            {
              name: "Brock St",
            },
  
            {
              name: "West Bank Dr",
            },
            {
              name: "Water St",
            },
            {
              name: " King St N",
            },
            {
              name: "Northminster United Church",
            },
            {
              name: "Robinson Place",
            },
            {
              name: "Hunter St W",
            },
            {
              name: "Romaine St",
            },
            {
              name: "Suite 45",
            },
            {
              name: "1545 Monaghan Rd",
            },
            {
              name: "Lansdowne St E",
            },
          ],
        },
        {
          name: "Hastings",
          cities: [
            {
              name: "Victoria St",
            },
          ],
        },
        {
          name: "Vancouver",
          cities: [
            {
              name: "Hastings St",
            },
            {
              name: " E Hastings St",
            },
            {
              name: "Suite 308",
            },
            {
              name: "E Hastings St",
            },
          ],
        },
        {
          name: "New Brunswick",
          cities: [
            {
              name: "Woodstock",
            },
            {
              name: "Sussex",
            },
            {
              name: "St. George",
            },
            {
              name: "Dieppe",
            },
            {
              name: "Bouctouche",
            },
            {
              name: "Miramichi",
            },
            {
              name: "St. Stephen",
            },
            {
              name: "Moncton",
            },
            {
              name: "Richibucto",
            },
            {
              name: "Chipman",
            },
            {
              name: "Saint-Quentin",
            },
            {
              name: "157 Water Street, Suite 802,P.O. Box 122",
            },
            {
              name: "Edmundston",
            },
            {
              name: "Saint John",
            },
            {
              name: "Shippagan",
            },
            {
              name: "Bathurst",
            },
            {
              name: "Fredericton",
            },
          ],
        },
        {
          name: " British Columbia",
          cities: [
            {
              name: "Coquitlam",
            },
            {
              name: "Surrey",
            },
            {
              name: "Burnaby",
            },
            {
              name: "Abbotsford",
            },
            {
              name: "North Vancouver,",
            },
            {
              name: "Langley",
            },
            {
              name: "North Delta",
            },
            {
              name: "Vancouver",
            },
            {
              name: "Dawson Creek",
            },
            {
              name: "West Vancouver",
            },
            {
              name: "Richmond",
            },
          ],
        },
        {
          name: "Plympton-Wyoming",
          cities: [
            {
              name: "London Line",
            },
          ],
        },
        {
          name: "Windsor",
          cities: [
            {
              name: "Suite 301",
            },
            {
              name: "Janette Ave",
            },
            {
              name: "Pelissier St",
            },
            {
              name: "Ouellette Ave, Suite 205",
            },
            {
              name: "Suite 102",
            },
            {
              name: " Chatham St E",
            },
            {
              name: " Ouellette Ave, Suite 201",
            },
            {
              name: " Langlois Ave",
            },
            {
              name: "Wyandotte St E",
            },
            {
              name: "Forest Glade Dr",
            },
            {
              name: "Sandwich St",
            },
            {
              name: "Ouellette Ave, Suite 201",
            },
            {
              name: "Glengarry Ave",
            },
            {
              name: "Suite 200",
            },
            {
              name: "Louis Ave",
            },
            {
              name: " Sandwich St",
            },
            {
              name: "Victoria Ave",
            },
            {
              name: "Church St",
            },
            {
              name: " Cameron Ave",
            },
            {
              name: "City Hall Square S",
            },
            {
              name: "McDougall Ave",
            },
            {
              name: "Suite 100",
            },
            {
              name: "Tecumseh Rd W",
            },
            {
              name: "Meadowbrook Lane, Suite 100",
            },
            {
              name: " Tecumseh Rd E",
            },
            {
              name: "Cantelon Dr",
            },
            {
              name: "Tecumseh Rd E",
            },
            {
              name: "Suite 101",
            },
            {
              name: " Suite 103",
            },
            {
              name: "Ouellette Ave",
            },
            {
              name: "Cameron Ave",
            },
            {
              name: "Hanna St W",
            },
            {
              name: "Suite 205",
            },
            {
              name: "cumseh Rd E",
            },
            {
              name: "Adstoll Ave",
            },
            {
              name: " Ouellette Ave",
            },
            {
              name: "Howard Ave",
            },
            {
              name: " Dougall Ave",
            },
            {
              name: "Forest Glade Dr,",
            },
            {
              name: "Ouellette Ave, Suite 202",
            },
            {
              name: "Langlois Ave",
            },
            {
              name: " McDougall St",
            },
            {
              name: "Ouellette Ave,",
            },
            {
              name: "Suite 410",
            },
            {
              name: "Ouellette Ave, Suite 203",
            },
            {
              name: "Ouellette Ave, Suite 204",
            },
            {
              name: "Dougall Ave",
            },
          ],
        },
        {
          name: "Peninsula",
          cities: [
            {
              name: "South Bruce",
            },
            {
              name: "Northern Bruce",
            },
          ],
        },
        {
          name: "Warwick",
          cities: [
            {
              name: "Simcoe St",
            },
          ],
        },
        {
          name: "M'Chigeeng First Nation",
          cities: [{}],
        },
        {
          name: "Arran-Elderslie",
          cities: [{}],
        },
        {
          name: "Saugeen Shores",
          cities: [
            {
              name: "Unit 119",
            },
          ],
        },
        {
          name: "Thessalon (Town Of)",
          cities: [
            {
              name: "Suite 201",
            },
          ],
        },
        {
          name: "Temiskaming Shores",
          cities: [
            {
              name: "May St S",
            },
            {
              name: " May St S",
            },
            {
              name: " Whitewood Ave",
            },
            {
              name: "Armstrong St",
            },
          ],
        },
        {
          name: "Watford",
          cities: [
            {
              name: "Nauvoo Rd",
            },
          ],
        },
        {
          name: "Mitchell",
          cities: [
            {
              name: "Francis St.",
            },
          ],
        },
        {
          name: "Sarnia",
          cities: [
            {
              name: "Oakdale Ave",
            },
            {
              name: " Lochiel St",
            },
            {
              name: "Davis St",
            },
            {
              name: "London Line",
            },
            {
              name: "Wellington St",
            },
            {
              name: "St Clair Corporate Centre",
            },
            {
              name: "Oxford St",
            },
            {
              name: "Front St N",
            },
            {
              name: "Victoria St S",
            },
            {
              name: "Modeland Rd",
            },
            {
              name: "Lochiel St",
            },
            {
              name: "Christina St N",
            },
            {
              name: "Afton Dr",
            },
            {
              name: "Finch Dr",
            },
  
            {
              name: "Exmouth St",
            },
          ],
        },
        {
          name: "Amherstburg",
          cities: [
            {
              name: "Richmond St",
            },
          ],
        },
        {
          name: " Toronto",
          cities: [{}],
        },
        {
          name: "Brampton",
          cities: [
            {
              name: "Steeles Ave E",
            },
            {
              name: "Finchgate Blvd",
            },
            {
              name: "Mountainash Rd",
            },
            {
              name: "Clarence St W",
            },
            {
              name: "Queen St E",
            },
            {
              name: "County Court Blvd",
            },
            {
              name: "Wexford Square",
            },
            {
              name: "queen St E",
            },
            {
              name: "Gateway Blvd",
            },
            {
              name: "Bartley Bull Pkwy",
            },
            {
              name: "Peel Centre Dr",
            },
            {
              name: "22 Melanie Dr",
            },
            {
              name: "McMurchy Ave S",
            },
            {
              name: "Honourable William G Davis Centre for Families",
            },
            {
              name: "Gillingham Dr",
            },
            {
              name: "Cottrelle Blvd",
            },
            {
              name: "Melanie Dr",
            },
            {
              name: "Balmoral Dr",
            },
            {
              name: " McLaughlin Rd",
            },
            {
              name: "Hurontario St",
            },
          ],
        },
        {
          name: "Stouffville",
          cities: [
            {
              name: "Sandiford Dr",
            },
          ],
        },
        {
          name: "Stratford",
          cities: [
            {
              name: "90 John St S",
            },
            {
              name: "West Gore St",
            },
            {
              name: "Huron St",
            },
            {
              name: " Romeo St S",
            },
          ],
        },
        {
          name: "Alberta",
          cities: [
            {
              name: "Okotoks",
            },
            {
              name: "Grand Prairie",
            },
            {
              name: "Sherwood Park",
            },
            {
              name: "Brooks",
            },
            {
              name: "Lethbridge",
            },
            {
              name: "Calgary",
            },
            {
              name: "Grande Prairie",
            },
            {
              name: "Edmonton",
            },
            {
              name: "High River",
            },
            {
              name: "Fort McMurray",
            },
            {
              name: "Camrose",
            },
            {
              name: "Banff",
            },
            {
              name: "Red Deer",
            },
            {
              name: "Whitecourt",
            },
            {
              name: "Medicine Hat",
            },
            {
              name: "Edson",
            },
            {
              name: "Lloydminster",
            },
          ],
        },
        {
          name: "Owen Sound",
          cities: [
            {
              name: "Grey County Administration Building",
            },
            {
              name: "Owen Sound Courthouse",
            },
            {
              name: "Owen Sound Mosque",
            },
            {
              name: " Suite 24",
            },
            {
              name: "Suite 24",
            },
            {
              name: "1400 First Ave W",
            },
  
            {
              name: "Suite 2",
            },
            {
              name: "9th Ave E",
            },
            {
              name: "824 1st Ave W",
            },
            {
              name: "854 2nd Ave E",
            },
          ],
        },
        {
          name: "Marathon",
          cities: [
            {
              name: "Suite 105",
            },
            {
              name: "Ontario St",
            },
            {
              name: "Hemlo Dr",
            },
  
            {
              name: "Suite B",
            },
          ],
        },
        {
          name: " British Columbia\r",
          cities: [
            {
              name: "Coquitlam",
            },
          ],
        },
        {
          name: "Romaine St",
          cities: [
            {
              name: "Romaine St",
            },
          ],
        },
        {
          name: "Newmarket",
          cities: [
            {
              name: "Yonge St",
            },
            {
              name: "Eagle Street",
            },
  
            {
              name: "Stellar Dr",
            },
          ],
        },
        {
          name: "North Huron",
          cities: [
            {
              name: "152 Josephine St",
            },
            {
              name: "185 Josephine St",
            },
          ],
        },
        {
          name: "Barrie",
          cities: [
            {
              name: "Suite 204C",
            },
            {
              name: "Georgian Dr",
            },
            {
              name: "Suite 63A",
            },
            {
              name: "11 Lakeside Te",
            },
          ],
        },
        {
          name: "Central Huron",
          cities: [
            {
              name: "60 Mary St",
            },
          ],
        },
        {
          name: "Asphodel-Norwood",
          cities: [{}],
        },
      ],
    },
  ];
  