import { createContext, useContext, useEffect, useRef, useState } from "react";
import { onAuthStateChange, signOut as signOutFromFirebase, createUserWithEmailAndPassword, signInWithEmailPassword, sendPasswordResetEmail } from "../services/firebase/auth";
import * as userService from "../services/firebase/firestore/user";
import { Loading } from "./loading/loading.component";


const AuthContext = createContext();

export const AuthContextProvider = ({ children }) => {
    const [isLoading, setIsLoading] = useState(true);
    const [user, setUser] = useState(null);

    const disposeFunctions = useRef([]).current;

    const dispose = () => {
        disposeFunctions.forEach(disposeFunction => disposeFunction());
    }

    const createUserIfNotExists = async (authenticatedUser) => {
        const user = await userService.getUser(authenticatedUser.uid);
        if (!user) {
            console.log("user creating => ", authenticatedUser.email);
            await userService.createUser(authenticatedUser);
        }
        else {
            console.log("user exists => ", authenticatedUser.email);
        }
    };


    useEffect(() => {
        const unsubscribeAuthStateChanges = onAuthStateChange(user => {
            if (user) {
                createUserIfNotExists(user);
            }
            setUser(user);
            if (isLoading) {
                setIsLoading(false);
            }
        });
        disposeFunctions.push(unsubscribeAuthStateChanges);
        return dispose;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const signOut = () => {
        signOutFromFirebase();
        setUser(null);
    }

    const value = {
        user,
        createUserWithEmailAndPassword,
        signInWithEmailPassword,
        sendPasswordResetEmail,
        signOut,
    }
    if (isLoading) {
        return (
            <Loading />
        )
    }
    return (
        <AuthContext.Provider value={value}>
            {children}
        </AuthContext.Provider>
    )
}

export const useAuth = () => useContext(AuthContext);