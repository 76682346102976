import { css, styled } from "styled-components";

export const Container = styled.div`
  flex-direction: row;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  margin: 0 auto;
  background: #f0f0f0;
  display: grid;
  grid-template-columns: 1fr;

`;
export const StyledSection = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
`;
export const StyledContainer = styled.div`
  display: grid;
  width: 100%;
  height: calc(100vh - 61px);
  grid-template-columns: 1fr;
  @media screen and (min-width: 992px) {
    grid-template-columns: 1fr 1fr;
  }
`;
export const StyledCenter = styled.div`
  background: #fff;
  padding: 30px;
  border-radius: 10px;
  max-width: 430px;
  margin: auto auto;
 
`;
export const StyledFormHeading = styled.div`
  font-size: 18px;
  color: #4c4c4c;
  font-weight: 700;
  margin-bottom: 20px;
  @media screen and (min-width: 992px) {
    font-size: 22px;
  }
`;
export const StyledFormSection = styled.div`
  width: 100%;
  flex-direction: column;
`;
export const StyledSelect = styled.select`
  ${({ error }) => css`
    width: 100%;
    height: 36px;
    display: flex;
    margin-bottom: 10px;
    border-radius: 6px;
    padding-left: 10px;
    padding-right: 10px;
    outline: none;
    font-size: 14px;
    border: 1px solid ${error ? `red` : `#ebebeb`};
    background: #fafafa;
  `}
`;
export const StyledInput = styled.input`
  ${({ error }) => css`
    width: 100%;
    height: 36px;
    display: flex;
    margin-bottom: 10px;
    border: none;
    border-radius: 6px;
    padding-left: 10px;
    outline: none;
    background: #fafafa;
    font-size: 14px;
    border: 1px solid ${error ? `red` : `#ebebeb`};

    &::placeholder {
      color: #000;
    }
  `}
`;
export const StyledContinueButton = styled.button`
${({$isSecondary}) => css`
width: 100%;
background: ${$isSecondary ? "#E0E0E0" : "#3478f6"};
padding: 10px 16px;
border: 0;
color:${$isSecondary ? "#444" : "#fff"};
font-size: 14px;
font-weight: 500;
border-radius: 50px;
margin: 10px 0;
`}

`;
export const StyledSignOutButton = styled.button`
  width: 100%;
  border: 0;
  color: #4c4c4c;
  margin-top: 12px;
  background: transparent;
  font-weight: 600;
  text-decoration: underline;
`;

export const FormImageSection = styled.div`
  width: 100%; 
  display: none;
  @media screen and (min-width: 992px) {
    display: block;
    padding: 20px;
  }
`;
export const FormImage = styled.img`
height: 100%;
width: 100%;
object-fit: cover;
border-radius: 50px 0 50px 0;
`;
export const StyledFormGroup = styled.div`
  margin-bottom: 14px;
`;

export const StyledLabel = styled.div`
  font-size: 12px;
  color: #9c9c9c;
  font-weight: 600;
  margin-bottom: 8px;
`;
export const StyledInputCenter = styled.div`
  display: grid;
  grid-template-columns: 48% 1fr;
  flex-direction: row;
  gap: 10px;
`;
