import { styled } from "styled-components";

export const LoginFormContainer = styled.form`
  width: 90%;
`;
export const StyledLabel = styled.div`
  width: 100%;
  margin-bottom: 30px;
`;
export const StyledInputTitle = styled.div`
  font-size: 16px;
  color: #000;
  font-weight: 500;
  margin-bottom: 10px;
`;
export const Input = styled.input`
  width: 100%;
  height: 38px;
  background: #edf2f7;
  border: 1px solid #e2e8f0;
  padding-left: 10px;
  border-radius: 0.375rem;
`;
export const Submit = styled.input`
  width: 100%;
  height: 38px;
  background: #1976d2;
  border: 1px solid #e2e8f0;
  padding-left: 10px;
  border-radius: 0.375rem;
  color: #fff;
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
`;

export const StyledActionText = styled.span`
width: 100%;
display: block;
font-size: 14px;
color: #718096;
text-align: center;
margin-bottom: 20px;
`

export const StyledActionLinkButton = styled.button`
  background: none;
  border: none;
  color:inherit;
  font-size:inherit;
  cursor: pointer;
  &:hover {
  text-decoration:underline;
}

`

