import React from "react";
import {
  StyledFeatures,
  StyledFeaturesContainer,
  StyledFeaturesHead,
  StyledFeaturesSection,
  StyledFeaturesTitle,
} from "./features.style";
import { IoCheckmarkDone } from "react-icons/io5";

export const Features = () => (
  <StyledFeaturesContainer>
    <StyledFeaturesSection>
      <StyledFeaturesHead>What you get</StyledFeaturesHead>
      <StyledFeatures>
        <StyledFeaturesTitle>
          <IoCheckmarkDone size={24} color="#4caf50" /> Community & Actions
        </StyledFeaturesTitle>
      </StyledFeatures>
      <StyledFeatures>
        <StyledFeaturesTitle>
          <IoCheckmarkDone size={24} color="#4caf50" /> Information & Voice
        </StyledFeaturesTitle>
      </StyledFeatures>
      <StyledFeatures>
        <StyledFeaturesTitle>
          <IoCheckmarkDone size={24} color="#4caf50" /> Marketplace & Business
        </StyledFeaturesTitle>
      </StyledFeatures>
      <StyledFeatures>
        <StyledFeaturesTitle>
          <IoCheckmarkDone size={24} color="#4caf50" /> Database & More
        </StyledFeaturesTitle>
      </StyledFeatures>
    </StyledFeaturesSection>
  </StyledFeaturesContainer>
);
