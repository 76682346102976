import React from "react";
import { Container, Logo, StyledLogoLink } from "./header.styles";


export const Header = () => {
  return (
    <Container>
      <StyledLogoLink href="">
        <Logo src="../../../../../../images/logo.png" alt="" />
      </StyledLogoLink>
    </Container>
  );
};
