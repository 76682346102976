const borderRadius = "10px";
const defaultBoxShadow = "0px 4px 16px  rgba(0,0,0,0.1)";
const spacings = {
	xxs: "0.25rem",
	xs: "0.5rem",
	sm: "0.75rem",
	md: "1rem",
	lg: "1.5rem",
	xl: "2rem",
	xxl: "2.5rem",
};
const fontSize = {
	xxs: "0.75rem",
	xs: "0.875rem",
	sm: "1rem",
	md: "1.125rem",
	lg: "1.25rem",
	xl: "1.5rem",
	xxl: "2rem",
	xxxl: "2.5rem",
};

const fontWeights = {
	light: 300,
	regular: 400,
	medium: 500,
	bold: 600,
};

const light = {
	borderRadius,
	defaultBoxShadow,
	spacings,
	fontSize,
	fontWeights,
	colors: {
		body: "#ffffff",
		contrastBackground: "#000000",
		primaryText: "#333333",
		secondaryText: "#777777",
		tertiaryText: "#6C757D",
		contrastText: "#ffffff",
		primaryButton: "#0466c8",
		secondaryButton: "#ffffff",
		borderColor: "#e1e1e1",
		systemRed: "#e53935",
		defaultCardBackground: "#fb8500",
		successGreen: "#0ead69",
		defaultBorderColor: "#E9ECEF",
		placeholder: "#6C757D",
		transparent: "transparent",
		defaultLinkColor: "#007bff",
		errorBackground: "rgba(0,0,0,0.05)",
		errorText: "#cf3636",
		warningBackground: "#fff3cd",
		warningText: "#856404",
		primaryItemBackground: "#f8f8f8",
		mobileNavbarBackground: "#2196F3",
		tabColor: "#2a234f", 
		navbarBackground: "#f5f5f5",
		sidebarBackground: "#f5f5f5", 
		inputBackground: "#fff",
		white: "#fff",

	},
};
const dark = {
	borderRadius,
	defaultBoxShadow,
	spacings,
	fontSize,
	fontWeights,
	colors: {
		body: "#202125",
		contrastBackground: "#ffffff",
		primaryText: "#e1e1e1",
		secondaryText: "#d1d1d1",
		tertiaryText: "#bbb",
		contrastText: "#000000",
		primaryButton: "#0466c8",
		secondaryButton: "#000000",
		borderColor: "rgba(255, 255, 255, 0.125)",
		systemRed: "#e53935",
		defaultCardBackground: "#fb8500",
		successGreen: "#0ead69",
		defaultBorderColor: "#444",
		placeholder: "#b1b1b3",
		transparent: "transparent",
		defaultLinkColor: "#007bff",
		errorBackground: "rgba(0,0,0,0.2)",
		errorText: "#cf3636",
		warningBackground: "#fff3cd",
		warningText: "#856404",
		primaryItemBackground: "#2c2d33",
		mobileNavbarBackground: "#2196F3",
		navbarBackground: "#141517",
		sidebarBackground: "#141517",
		tabColor: "#e1dbff",
		inputBackground: "transparent",
		white: "#fff",
	},
};

export const themes = {
	light,
	dark,
};
