import React, { useState } from "react";
import { Input, LoginFormContainer, StyledActionLinkButton, StyledActionText, StyledInputTitle, StyledLabel, Submit } from "./login-form.styles";
import { useAuth } from "../../../contexts/auth.context";

const FormType = {
    Login: 1,
    Register: 2,
    ForgotPassword: 3

}

export const LoginForm = () => {
    const [formType, setFormType] = useState(FormType.Login)
    const forms = {
        [FormType.Login]: <Login setFormType={setFormType} />,
        [FormType.Register]: <Register setFormType={setFormType} />,
        [FormType.ForgotPassword]: <ForgotPassword setFormType={setFormType} />
    }
    return forms[formType];
}

const Login = ({ setFormType }) => {
    const { signInWithEmailPassword } = useAuth();
    const [email, setEmail] = useState("")
    const [password, setPassword] = useState("")
    const [isLoading, setIsLoading] = useState(false)

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsLoading(true);
        const { error } = await signInWithEmailPassword(email, password);
        setIsLoading(false);
        if (error) {
            alert(error);
        }
    }

    return (
        <LoginFormContainer onSubmit={handleSubmit}>
            <StyledLabel>
                <StyledInputTitle>Email</StyledInputTitle>
                <Input type="text" placeholder="Enter your email" value={email} onChange={e => setEmail(e.currentTarget.value)} />
            </StyledLabel>
            <StyledLabel>
                <StyledInputTitle>Password</StyledInputTitle>
                <Input type="password" placeholder="Enter password" value={password} onChange={e => setPassword(e.currentTarget.value)} />
            </StyledLabel>
            <StyledLabel>
                <Submit type="submit" value={isLoading ? "Loading..." : "Sign In"} />
            </StyledLabel>
            <StyledActionText>
                <StyledActionLinkButton type="button" onClick={() => setFormType(FormType.ForgotPassword)}>Forgot Your Password?</StyledActionLinkButton>
            </StyledActionText>
            <StyledActionText>
                Don't have an account? <StyledActionLinkButton type="button" onClick={() => setFormType(FormType.Register)}>Sign Up</StyledActionLinkButton>
            </StyledActionText>
        </LoginFormContainer>
    )
}

const Register = ({ setFormType }) => {
    const { createUserWithEmailAndPassword } = useAuth();
    const [isLoading, setIsLoading] = useState(false)
    const [formData, setFormData] = useState({
        email: "",
        password: "",
        passwordRepeat: ""
    });

    const setFormValue = (key, value) => {
        setFormData({
            ...formData,
            [key]: value
        })
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (formData.password !== formData.passwordRepeat) {
            alert("Passwords do not match");
            return;
        }
        setIsLoading(true);
        const { error } = await createUserWithEmailAndPassword(formData.email, formData.password);
        setIsLoading(false);
        if (error) {
            alert(error);
        }
    }

    return (
        <LoginFormContainer onSubmit={handleSubmit}>
            <StyledLabel>
                <StyledInputTitle>Email</StyledInputTitle>
                <Input type="text" placeholder="Enter your email" value={formData.email} onChange={e => setFormValue("email", e.currentTarget.value)} />
            </StyledLabel>
            <StyledLabel>
                <StyledInputTitle>Password</StyledInputTitle>
                <Input type="password" placeholder="Enter password" value={formData.password} onChange={e => setFormValue("password", e.currentTarget.value)} />
            </StyledLabel>
            <StyledLabel>
                <StyledInputTitle>Password Repeat</StyledInputTitle>
                <Input type="password" placeholder="Enter password" value={formData.passwordRepeat} onChange={e => setFormValue("passwordRepeat", e.currentTarget.value)} />
            </StyledLabel>
            <StyledLabel>
                <Submit type="submit" value={isLoading ? "Loading..." : "Sign Up"} />
            </StyledLabel>
            <StyledActionText>
                Do you have an account? <StyledActionLinkButton type="button" onClick={() => setFormType(FormType.Login)}>Sign In</StyledActionLinkButton>
            </StyledActionText>
        </LoginFormContainer>
    )
}

const ForgotPassword = ({ setFormType }) => {
    const [email, setEmail] = useState("");
    const [isLoading, setIsLoading] = useState(false)
    const { sendPasswordResetEmail } = useAuth();
    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsLoading(true);
        const { error } = await sendPasswordResetEmail(email);
        setIsLoading(false);
        if (error) {
            alert(error);
        }
        else {
            alert("Password reset email sent");
            setFormType(FormType.Login);
        }
    }
    return (
        <LoginFormContainer onSubmit={handleSubmit}>
            <StyledLabel>
                <StyledInputTitle>Email</StyledInputTitle>
                <Input type="text" placeholder="Enter your email" value={email} onChange={e => setEmail(e.currentTarget.value)} />
            </StyledLabel>
            <StyledLabel>
                <Submit type="submit" value={isLoading ? "Loading..." : "Send Password Reset Email"} />
            </StyledLabel>
            <StyledActionText>
                Do you have an account? <StyledActionLinkButton type="button" onClick={() => setFormType(FormType.Login)}>Sign In</StyledActionLinkButton>
            </StyledActionText>
        </LoginFormContainer>
    )
}