import React from "react";
import {
  Container,
  StyledCenter,
  StyledConfirmationBg,
  StyledContainer,
  StyledContent,
  StyledContinueButton,
  StyledFeatures,
  StyledFeaturesTitle,
  StyledImage,
  StyledImageSection,
  StyledList,
  StyledListItem,
  StyledListSection,
  StyledSection,
  StyledTitle,
} from "./styles";
import { IoCheckmarkDone } from "react-icons/io5";
import { Header } from "../auth/header/header.component";

const Confirmation = ({ setStage }) => {
  return (
    <>
      <Container>
        <Header />
        <StyledCenter>
          <StyledConfirmationBg
            src="../../../../../../images/confirmation-bg.png"
            alt=""
          />
          <StyledSection>
            <StyledTitle>Welcome to One Church Hub</StyledTitle>
            <StyledContent>
              We do life together here, give and take charity, share information
              and lots more. Our friendly professionals will help you find and
              connect your teams to the services and tools they need for
              ministry accomplishments while building one body with different
              and many parts.
            </StyledContent>
            <StyledContainer>
              <StyledContinueButton
                onClick={() =>
                  navigator.clipboard.writeText("https://onechurchhub.com/")
                }
              >
                Copy link & Share
              </StyledContinueButton>
            </StyledContainer>
          </StyledSection>

          <StyledImageSection>
            <StyledImage src="../../../../../../images/success.png" alt="" />
            <StyledFeatures>
              <StyledFeaturesTitle>What you get</StyledFeaturesTitle>
              <StyledListSection>
                <StyledList>
                  <StyledListItem>
                    <IoCheckmarkDone size={20} color="#3478F6" /> Community &
                    Actions
                  </StyledListItem>
                  <StyledListItem>
                    <IoCheckmarkDone size={20} color="#3478F6" /> Information &
                    Voice
                  </StyledListItem>
                  <StyledListItem>
                    <IoCheckmarkDone size={20} color="#3478F6" /> Marketplace &
                    Business
                  </StyledListItem>
                  <StyledListItem>
                    <IoCheckmarkDone size={20} color="#3478F6" /> Database &
                    More
                  </StyledListItem>
                </StyledList>
              </StyledListSection>
            </StyledFeatures>
          </StyledImageSection>
        </StyledCenter>
      </Container>
    </>
  );
};

export default Confirmation;
