import styled, { css } from "styled-components";

export const ModalContainer = styled.div`
	${({ $width, $height, $showFromDown }) => css`
		z-index: 10000;
		position: fixed;
		top: 0;
		bottom: 0;
		left: 0;
		right: 0;
		margin: auto auto;
		width: 100%;
		padding: 1rem;
		transition: 0.8s all ease;
		overflow: hidden;
		display: flex;
		justify-content: center;
		align-items: center;
		flex-direction: column;
		box-sizing: border-box;
		${$showFromDown &&
		css`
			transition: 0.8s all ease;
			top: auto;
			padding: 0;
			animation: slideDownToUp 0.2s;
			@-webkit-keyframes slideDownToUp {
				from {
					bottom: -100%;
				}
				to {
					bottom: 0%;
				}
			}
		`}
		@media screen and (min-width: 991px) {
			max-width: ${$width ? $width : "100%"};
			height: ${$height ? $height : "100%"};
		}
	`}
`;

export const ModalDialog = styled.div`
	${({ $height, theme }) => css`
		position: relative;
		background-color: ${theme.colors.body};
		border-radius: 10px;
		overflow: hidden;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: start;
		width: 100%;
		padding: 1rem;
		height: 100%;
		@media screen and (min-width: 991px) {
			height: ${$height ? $height : "100%"};
		}
	`}
`;

export const StyledModalBody = styled.div`
	display: block;
	width: 100%;
	height: 100%;
	overflow: hidden;
	overflow-x: hidden;
	overflow-y: hidden;
`;
export const ModalHeader = styled.div`
${({ $height, theme }) => css`
	width: 100%;
	display: flex;
	justify-content: flex-start;
	position: relative;
	font-size: 18px;
	font-weight: 500;
	text-align: center;
	margin-bottom: 1rem;
	color:  ${theme.colors.tertiaryText};
	`}
`;

export const CloseButton = styled.button`
	position: absolute;
	right: 0;
	top: 0;
	border: none;
	background-color: inherit;
	cursor: pointer;
	align-self: flex-end;
`;

export const Backdrop = styled.div`
	${({ theme }) => css`
		border: none;
		padding: 0;
		border-radius: 0;
		background: rgba(0, 0, 0, 0.65);
		bottom: 0;
		cursor: pointer;
		left: 0;
		opacity: 0;
		position: fixed;
		right: 0;
		top: 0;
		transition: opacity 350ms cubic-bezier(0, 0, 0.58, 1);
		z-index: 999;
		&[aria-expanded="false"] {
			width: 0;
		}
		&[aria-expanded="true"] {
			width: 100%;
			opacity: 1;
		}
	`}
`;
