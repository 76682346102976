import React from "react";
import { Routes, Route, BrowserRouter } from "react-router-dom";
import RegisterPage from "../pages/register.js";
import Confirmation from "../components/confirmation/index.js";


const MainRouter = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<RegisterPage />} />
        <Route path="/success" element={<Confirmation />} />
      </Routes>
    </BrowserRouter>
  );
};

export default MainRouter;
