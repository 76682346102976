import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getFunctions } from "firebase/functions";

const firebaseConfig = {
  apiKey: "AIzaSyCaoAQDG_nJQiH9T-RqvkxCfF-eg6wHQHk",
  authDomain: "churchhub-ced46.firebaseapp.com",
  projectId: "churchhub-ced46",
  storageBucket: "churchhub-ced46.appspot.com",
  messagingSenderId: "1084395573240",
  appId: "1:1084395573240:web:4cc8512554e17f8747900d",
  measurementId: "G-XJ13YE6YPW"
};

const app = initializeApp(firebaseConfig);

export const auth = getAuth(app);
export const db = getFirestore(app);
export const functions = getFunctions(app);
// connectFunctionsEmulator(functions, "127.0.0.1", 5001);