import React, { Fragment, useEffect } from "react";
import CloseSvg from "../../icons/close.svg";
import {
  ModalContainer,
  CloseButton,
  StyledModalBody,
  ModalDialog,
  Backdrop,
  ModalHeader,
} from "./modal.styles";

const Modal = ({
  active,
  children,
  closePopUp,
  backdropClose = true,
  showCloseIcon,
  width = "",
  height = "",
  title = "",
  showFromDown = false,
}) => {
  useEffect(() => {
    document.body.style.overflow = "hidden";
    return () => (document.body.style.overflow = "unset");
  }, []);

  const handleBackdropClick = () => {
    if (backdropClose) {
      closePopUp();
    } else {
      return;
    }
  };

  return (
    <Fragment>
      <ModalContainer
        $width={width}
        $height={height}
        $showFromDown={showFromDown}
      >
        <ModalDialog $height={height}>
          <ModalHeader>
            {title && title}
            {showCloseIcon && (
              <CloseButton onClick={closePopUp}>
                <CloseSvg />
              </CloseButton>
            )}
          </ModalHeader>
          <StyledModalBody>{children}</StyledModalBody>
        </ModalDialog>
      </ModalContainer>
      <Backdrop
        onClick={handleBackdropClick}
        aria-expanded={String(active)}
        tabIndex="-1"
        aria-hidden="true"
      />
    </Fragment>
  );
};

export default Modal;
