import { styled } from "styled-components";

export const Container = styled.div`
width: 100%;
display: flex;
justify-content: center;
align-items: center;
padding: 20px;
position: relative; 
border-bottom: 1px solid #EDF2F7;
   @media screen and (min-width: 992px) {
    padding: 20px;
    justify-content: flex-start;
    align-items: flex-start;
    margin-bottom: 0px;
  }
  background: #fff;
`;
export const StyledLogoLink = styled.a``
export const Logo = styled.img`
  width: 180px;
`

export const StyledUserSection = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  
`;
export const StyledUserName = styled.div`
  font-size: 14px;
  margin-right: 1.5rem;
  display: none;
  small{
    display: block;
  }
  @media screen and (min-width: 992px) {
    display: block;
  }
`;
export const StyledSignOutButton = styled.button`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  border: none;
  background: #f1f5f9;
  border:1px solid #dfe4e9;
  color: #1c1c1c;
  padding: .5rem .7rem;
  border-radius: .375rem;
  svg {
    margin-left: .5rem;
  }
`;