import styled, { css } from "styled-components";

export const StyledPaymentContainer = styled.div`
	${({ theme }) => css`
		display: grid;
		grid-template-columns: 1fr;
		gap: 2rem;
		align-items: center;
		width: 100%;
		max-width: 940px;
		margin: 0 auto;
		border: 1px solid ${theme.colors.defaultBorderColor};
		padding: 2rem;
		border-radius: 1rem;
		box-shadow: ${theme.defaultBoxShadow};
	`}
`;
export const StyledPaymentContent = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	width: 100%;
	max-width: 600px;
	margin: 0 auto;
`;

export const StyledHeading = styled.h1`
	${({ theme }) => css`
		font-size: 20px;
		font-weight: 600;
		color: ${theme.colors.primaryText};
		padding-bottom: 1rem;
		@media screen and (min-width: 992px) {
			font-size: 32px;
		}
	`}
`;

export const StyledContent = styled.div`
	line-height: 1.4;
`;

export const StyledParagraph = styled.p`
	font-size: 14px;
	line-height: 1.4;
	font-weight: 400;
	text-align: center;
	@media screen and (min-width: 992px) {
		font-size: 16px;
	}
`;

export const StyledPaymentPlanContainer = styled.div`
	display: grid;
	grid-template-columns: 1fr;
	gap: 1rem;
	width: 100%;
	max-width: 600px;
	margin: 0 auto;
	@media screen and (min-width: 992px) {
		grid-template-columns: 1fr 1fr;
	}
`;
export const StyledPaymentPlan = styled.label`
	${({ $featured, theme }) => css`
		display: flex;
		justify-content: center;
		align-items: center;
		width: 100%;
		background: ${theme.colors.body};
		border-radius: 0.5rem;
		overflow: hidden;
		padding: 1rem;
		height: 70px;
		box-sizing: border-box;
		cursor: pointer;
		border: 2px solid ${theme.colors.defaultBorderColor};
		${$featured &&
		css`
			border: 2px solid ${theme.colors.primaryButton};
			background: ${theme.colors.primaryItemBackground};
		`}
	`}
`;

export const StyledHeader = styled.div`
	${({ theme }) => css`
		display: flex;
		flex-direction: column;
		color: ${theme.colors.primaryText};
	`}
`;

export const StyledFreeText = styled.span`
	${({ theme }) => css`
		display: flex;
		justify-content: center;
		align-items: center;
		font-size: 12px;
		font-weight: 400;
		color: ${theme.colors.secondaryText};
	`}
`;

export const StyledPlanPrice = styled.span`
	font-size: 18px;
	font-weight: 600;
`;

export const StyledInput = styled.input`
	display: none;
`;

export const StyledFeatureList = styled.ul`
	list-style: none;
	padding: 1rem 0;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-wrap: wrap;
	padding: 1rem 0 0 1rem;
	max-width: 100%;
	margin: 0 auto;

	@media screen and (min-width: 992px) {
		padding: 2rem 0 1rem 2rem;
		max-width: 500px;
		margin: 0 auto;
	}
`;
export const StyledFeatureListItem = styled.li`
	display: flex;
	justify-content: flex-start;
	align-items: center;
	font-size: 14px;
	padding: 0.2rem 0;
	min-width: 230px;
	svg {
		margin-right: 0.5rem;
	}
	@media screen and (min-width: 992px) {
		padding: 0.4rem 0;
		font-size: 16px;
	}
`;

export const StyledPurchaseButton = styled.button`
	${({ theme }) => css`
		display: flex;
		justify-content: center;
		align-items: center;
		margin: 1rem auto;
		font-size: 16px;
		width: 100%;
		border: none;
		padding: 0.7rem 1rem;
		border-radius: 0.375rem;
		max-width: 260px;
		background: ${theme.colors.primaryButton};
		color: ${theme.colors.body};
		cursor: pointer;
	`}
`;

export const StyledLoading = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	text-align: center;
	height: 100%;
	font-size: 18px;
`;

export const StyledSpinner = styled.div`
	margin-bottom: 1rem;
	svg {
		animation: rotation 1s infinite linear;
	}

	@keyframes rotation {
		from {
			transform: rotate(0deg);
		}
		to {
			transform: rotate(359deg);
		}
	}
`;
