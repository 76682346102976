import { styled } from "styled-components";

export const StyledFeaturesContainer = styled.div`
  width: 100%;
  height:100vh;
  background: #F0F0F0;
  padding: 50px;
  display: none;
  justify-content:center;
  flex-direction: column;
  @media screen and (min-width: 992px) {
    display:flex;
    }
`
export const StyledFeaturesSection = styled.div`
`
export const StyledFeaturesHead = styled.div`
    font-size: 30px;
    color: #000;
    font-weight: 600;
    margin-bottom: 40px;
`
export const StyledFeatures = styled.div`
    margin-bottom: 26px;
`
export const StyledFeaturesTitle = styled.div`
    font-size: 18px;
    font-weight: 600;
    color: #000;
    margin-bottom: 10px;
    display: flex;
    gap: 10px;
`
export const StyledFeaturesContent = styled.div`
    font-size: 16px;
    font-weight: 400;
    color: #000;
`