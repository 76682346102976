import { ThemeProvider } from "styled-components";
import MainRouter from "./router";
import { themes } from "./theme/theme";
import { GlobalStyle } from "./theme/global-style";
import { AuthContextProvider } from "./contexts/auth.context";

function App() {
  return (
    <ThemeProvider theme={themes["light"]}>
      <AuthContextProvider>
        <GlobalStyle />
        <MainRouter />
      </AuthContextProvider>
    </ThemeProvider>
  );
}

export default App;
